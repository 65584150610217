import { Module } from 'vuex';
import { RootState } from './root'; // Import the RootState type

// Define the state type
interface VehicleState {
  // filters
  filterOn: boolean | number,
  maxPrice: number | undefined,
  minPrice: number | undefined,
  mileage: number | undefined,
  year: number | undefined,

  //sort
  sortOn: boolean | number,
  priceSort: string | undefined,
  kmSort: string | undefined,
  yearSort: string | undefined,
}

const vehicleModule: Module<VehicleState, RootState> = {
  namespaced: true,
  state: {
    // filters
    filterOn: false,
    maxPrice: undefined,
    minPrice: undefined,
    mileage: undefined,
    year: undefined,

    // sort
    sortOn: false,
    priceSort: undefined,
    kmSort: undefined,
    yearSort: undefined,
  },

  getters: {
    // filters
    getFilterOn: (state) => state.filterOn,
    getMaxPrice: (state) => state.maxPrice,
    getMinPrice: (state) => state.minPrice,
    getMileage: (state) => state.mileage,
    getYear: (state) => state.year,

    // sort
    getSortOn: (state) => state.sortOn,
    getPriceSort: (state) => state.priceSort,
    getKmSort: (state) => state.kmSort,
    getYearSort: (state) => state.yearSort,
  },

  mutations: {
    // filters
    SET_FILTER_ON: (state, filterOn) => {
      state.filterOn = filterOn;
    },
    SET_MAX_PRICE: (state, maxPrice) => {
      state.maxPrice = maxPrice;
    },
    SET_MIN_PRICE: (state, minPrice) => {
      state.minPrice = minPrice;
    },
    SET_MILEAGE: (state, mileage) => {
      state.mileage = mileage;
    },
    SET_YEAR: (state, year) => {
      state.year = year;
    },

    // sort
    SET_SORT_ON: (state, sortOn) => {
      state.sortOn = sortOn;
    },
    SET_PRICE_SORT: (state, priceSort) => {
      state.priceSort = priceSort;
    },
    SET_KM_SORT: (state, kmSort) => {
      state.kmSort = kmSort;
    },
    SET_YEAR_SORT: (state, yearSort) => {
      state.yearSort = yearSort;
    }
  },

  actions: {
    applyFilters: ({ commit }) => {
      commit('SET_FILTER_ON', true);
    },
    clearFilters: ({ commit }) => {
      commit('SET_FILTER_ON', false);
      commit('SET_MAX_PRICE', undefined);
      commit('SET_MIN_PRICE', undefined);
      commit('SET_MILEAGE', undefined);
      commit('SET_YEAR', undefined);
    },

    applySort: ({ commit }) => {
      commit('SET_SORT_ON', true);
    },
    clearSort: ({ commit }) => {
      commit('SET_SORT_ON', false);
      commit('SET_PRICE_SORT', undefined);
      commit('SET_KM_SORT', undefined);
      commit('SET_YEAR_SORT', undefined);
    },
  },
};

export default vehicleModule;
