<template>
  <div>
    <NavBar />

    <div v-if="!isLoading" class="container" style="justify-content: center">
      <h2 class="add-title">Edit Vehicle Page</h2>
      <div class="row gutters">
        <div class="card h-100">
          <div class="card-body">
            <div class="row gutters my-card">
              <div>
                <h6 class="mb-2 text-primary heading-info">Vehicle Details:</h6>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Title<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Name of Vehicle"
                      v-model="vehicleInfo.title" required />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Mileage<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter Mileage" min="0"
                      v-model="vehicleInfo.mileage" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Make:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Make Type"
                      v-model="vehicleInfo.make" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Model<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Model"
                      v-model="vehicleInfo.model" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="price" class="form-label">Price<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="price" min="0" placeholder="Enter Price" v-model="vehicleInfo.price" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Year Model:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Year Model"
                      v-model="vehicleInfo.dateOfLicensing" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutters">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 class="mt-3 mb-2 text-primary heading-info">Vehicle Specifications:</h6>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Colour:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Colour"
                      v-model="vehicleInfo.color" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Body Type:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Body Type"
                      v-model="vehicleInfo.bodyType" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Feul Type:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Feul Type"
                      v-model="vehicleInfo.fuelType" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Feul Tank Capacity:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter Feul Tank Capacity" min="0"
                      v-model="vehicleInfo.fuelTankCapacity" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Gearbox:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Gearbox Type" min="0"
                      v-model="vehicleInfo.gearbox" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Gears:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter Number of Gears" min="0"
                      v-model="vehicleInfo.gears" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Doors:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter Number of Doors" min="0"
                      v-model="vehicleInfo.doors" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Seats:</label>
                  <div class="input-wrapper">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter Number of Seats" min="0"
                      v-model="vehicleInfo.seats" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Service History:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Service History"
                      v-model="vehicleInfo.serviceHistory" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Stock Code:</label>
                  <div class="input-wrapper">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Stock Code"
                      v-model="vehicleInfo.stockCode" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="category" class="form-label">Category:</label>
                  <div class="input-wrapper">
                    <select class="form-control" id="category" v-model="vehicleInfo.category">
                      <option value="sedan">Sedan</option>
                      <option value="hatchback">Hatchback</option>
                      <option value="suv">SUV</option>
                      <option value="singleCab">Single Cab</option>
                      <option value="doubleCab">Double Cab</option>
                      <option value="sevenSeater">7 Seater</option>
                      <option value="bus">Bus</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="priority" class="form-label">Priority:</label>
                  <div class="input-wrapper">
                    <select class="form-control" id="priority" v-model="vehicleInfo.priority">
                      <option value="1">1 (very high)</option>
                      <option value="2">2 (high)</option>
                      <option value="3">3 (medium)</option>
                      <option value="4">4 (low)</option>
                      <option value="5" selected>5 (very low)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutters">
              <label for="notes" class="form-label">Notes:</label>
                <div class="input-multi">
                  <textarea class="form-control note-form-control" id="notes" placeholder="Enter Notes" v-model="vehicleInfo.notes"></textarea>
                </div>
            </div>
            <div class="row gutters my-card">
              <div>
                <h6 class="mb-2 text-primary heading-info" style="margin-top: 20px">Vehicle Images:</h6>
              </div>
              <div class="small-image-wrapper">
                <div class="small-image-container" style="overflow-x: auto; overflow-y: hidden;">
                  <div class="image-scroll">
                    <div v-for="(image, index) in vehicleInfo.images" :key="index" :class="{ '': index === imageIndex }"
                      @click="showRemoveConfirmation(index)" class="image-container">
                      <img :src="image" alt="Product Image" :class="activeImageClass(index)" />
                      <span class="remove-icon" @click="showRemoveConfirmation(index)">X</span>
                    </div>
                  </div>
                  <div v-if="vehicleInfo.images.length === 0" :key="index" class="img-fluid" style="align-items:center">
                    <img :src="noImage" alt="Product Image" :class="activeImageClass(index)" />
                  </div>
                </div>

                <label for="fileInput" class="plus-icon">
                  <input type="file" id="fileInput" ref="fileInput" multiple @change="handleFileUpload"
                    accept="image/jpeg, image/png" style="display: none" />
                  <i class="fas fa-plus"></i>
                </label>
              </div>
            </div>
            <div class="row gutters" style="margin-top:20px">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="text-right">
                  <button type="button" class="btn btn-danger vButton" @click="showVehicleRemoveConfirmation">Delete Vehicle</button>
                  <!-- <button type="button" id="submit" name="submit" class="btn btn-secondary vButton">Discard
                    Changes</button> -->
                  <button type="button" class="btn btn-primary vButton" @click="onUpdateVehicle">Save Changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center loading-icon loadingHeight">
      <!-- Use v-if to conditionally display the loading icon -->
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Image Removal Confirmation Modal -->
    <div class="modal-overlay" v-if="isRemoveConfirmationVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageRemovalModalLabel">
              Remove Image
            </h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close"
              @click="closeRemoveConfirmationModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to remove this image?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeRemoveConfirmationModal">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="removeImageConfirmed">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Vehicle Removal Confirmation Modal -->
    <div class="modal-overlay" v-if="isVehicleRemoveConfirmationVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageRemovalModalLabel">
              Remove Vehicle
            </h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close"
              @click="closeVehicleRemoveConfirmationModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to remove this vehicle?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeVehicleRemoveConfirmationModal">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="onDeleteVehicle">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal-overlay" v-if="isErrorModalVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title-error" id="errorModalLabel">Error</h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close" @click="closeErrorModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal-overlay" v-if="isSuccessModalVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="successModalLabel">Successful</h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close" @click="closeSuccessModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-message">
            <p>{{ successMessage }}</p>
            <div class="success-delete-div" v-if="showDeleteSuccess">
              <button class="btn btn-primary btn-success-delete" @click="deleteSuccessClick">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { isMobileDevice } from "@/common/commonFunctions";
import { Vehicle, deleteVehicle, fetchSingleVehicle, updateVehicle } from "@/api/vehicle";
import { containerClient } from "@/api/azureBlob";
import { v4 as uuidv4 } from 'uuid';
import { extractUUIDFromBlobUrl } from "@/util/common";

export default defineComponent({
  name: "VehicleView",
  components: {
    NavBar,
    FooterComponent: Footer,
  },
  data() {
    return {
      isHovering: false,
      imageIndex: 0,
      isRemoveConfirmationVisible: false,
      isVehicleRemoveConfirmationVisible: false,
      imageIndexToRemove: null as number | null,
      selectedImages: [] as File[],
      isErrorModalVisible: false,
      errorMessage: "",
      isSuccessModalVisible: false,
      successMessage: "",
      showDeleteSuccess: false,
      noImage:
        "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg",
      vehicleInfo: {
        id: "",
        title: "2015 BMW X5 xDrive30d",
        images: [
          'https://img.autotrader.co.za/26099264/Crop800x600',
          'https://img.autotrader.co.za/26099262/Crop800x600',
          'https://img.autotrader.co.za/26099263/Crop800x600',
          'https://img.autotrader.co.za/26099264/Crop800x600',
          'https://img.autotrader.co.za/26099262/Crop800x600',
          'https://img.autotrader.co.za/26099263/Crop800x600',
          'https://img.autotrader.co.za/26099264/Crop800x600',
          'https://img.autotrader.co.za/26099262/Crop800x600',
          'https://img.autotrader.co.za/26099263/Crop800x600',
          'https://img.autotrader.co.za/26099264/Crop800x600',
        ],
        mileage: 80000,
        colour: "Blue",
        dateOfLicensing: "2023",
        serviceHistory: "unknown",
        stockCode: "123456",
        make: "BMW",
        model: "M3",
        bodyType: "Sedan",
        gearbox: "Automatic",
        gears: 6,
        fuelType: "Petrol",
        fuelCapactiy: 59,
        doors: 4,
        seats: 4,
        price: 150000,
        category: "Vehicle",
        priority: 5,
      } as Vehicle,
      isLoading: false
    };
  },
  async mounted() {
    this.isLoading = true;
    this.vehicleInfo.id = this.$route.params.id;
    const vehicleToEdit = await fetchSingleVehicle({ id: this.vehicleInfo.id as string });
    this.isLoading = false;
    if (vehicleToEdit) {
      this.vehicleInfo = vehicleToEdit;
    } else {
      this.showErrorModal('Error fetching vehicle data. Please try again.');
    }
  },

  methods: {
    changeImage(step: any) {
      this.imageIndex =
        (this.imageIndex + step + this.vehicleInfo.images.length) % this.vehicleInfo.images.length;
    },
    selectImage(index: any) {
      this.imageIndex = index;
    },
    activeImageClass(index: any) {
      if (index === this.imageIndex) {
        return "img-fluid small-image small-image-selected";
      } else {
        return "img-fluid small-image";
      }
    },
    isMobile() {
      return isMobileDevice();
    },
    showErrorModal(message: string) {
      this.isErrorModalVisible = true;
      this.errorMessage = message;
    },
    closeErrorModal() {
      this.isErrorModalVisible = false;
      this.errorMessage = "";
    },
    showSuccessModal(message: string) {
      this.isSuccessModalVisible = true;
      this.successMessage = message;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
      this.successMessage = "";
    },
    async handleFileUpload() {
      const inputElement = this.$refs.fileInput as HTMLInputElement;
      const files = inputElement.files;
      // Store selected files in a data property or component state
      if (files) {
        // Filter selected files to allow only JPG and PNG
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const filteredFiles = Array.from(files).filter((file) => {
          const extension = file.name.split(".").pop()?.toLowerCase();
          return extension && allowedExtensions.includes(extension);
        });
        // Explicitly cast 'files' to an array using Array.from
        this.selectedImages = filteredFiles as File[];

        for (const file of filteredFiles) {

          const blobName = uuidv4();
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);

          await blockBlobClient.uploadBrowserData(file);
          this.vehicleInfo.images.push(blockBlobClient.url);
        }
      }
    },
    removeImage(index: number) {
      const shouldRemove = window.confirm("Are you sure you want to remove this image?");
      if (shouldRemove) {
        this.vehicleInfo.images.splice(index, 1);
        this.imageIndex = 0;
      }
    },
    // Method to show the remove confirmation dialog
    showRemoveConfirmation(index: number) {
      this.imageIndexToRemove = index;
      this.isRemoveConfirmationVisible = true;
    },
    // Method to close the remove confirmation modal
    closeRemoveConfirmationModal() {
      this.isRemoveConfirmationVisible = false;
    },
    // Method to show the remove confirmation dialog
    showVehicleRemoveConfirmation() {
      this.isVehicleRemoveConfirmationVisible = true;
    },
    // Method to close the remove confirmation modal
    closeVehicleRemoveConfirmationModal() {
      this.isVehicleRemoveConfirmationVisible = false;
    },
    deleteSuccessClick(){
      this.$router.replace("/");
    },
    // Method to remove the image when "Remove" is clicked in the dialog
    async removeImageConfirmed() {
      if (this.imageIndexToRemove === null) {
        this.showErrorModal('Error removing image. Please try again.');
        return;
      }
      const blobName = extractUUIDFromBlobUrl(this.vehicleInfo.images[this.imageIndexToRemove!]);
      if (blobName) {
        const blobClient = containerClient.getBlockBlobClient(blobName);

        try {
          await blobClient.delete();
          this.vehicleInfo.images.splice(this.imageIndexToRemove, 1);
          this.imageIndex = 0;
          this.isRemoveConfirmationVisible = false;
          this.imageIndexToRemove = null;
          // console.log(`Image "${blobName}" deleted successfully.`);
        } catch (error) {
          console.error(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
          this.showErrorModal(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
        }
      } else {
        this.showErrorModal('Error, Blob is null. Please try again.');
      }
    },
    async onDeleteVehicle() {
      this.isLoading = true;

      // delete images from azure blob storage
     for (let i=0; i<this.vehicleInfo.images.length; i++) {
      const blobName = extractUUIDFromBlobUrl(this.vehicleInfo.images[i]);
      if (blobName) {
        const blobClient = containerClient.getBlockBlobClient(blobName);

        try {
          await blobClient.delete();
          // console.log(`Image "${blobName}" deleted successfully.`);
        } catch (error) {
          console.error(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
          this.showErrorModal(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
        }
      } else {
        this.showErrorModal('Error, Blob is null. Please try again.');
      }
     }

      // delete the vehicle from the database
      const deletedVehicle = await deleteVehicle({ id: this.vehicleInfo.id as string });
      this.isLoading = false;
      if (deletedVehicle) {
        this.closeVehicleRemoveConfirmationModal();
        this.showDeleteSuccess = true
        this.showSuccessModal('Successfully Deleted vehicle!');
      } else {
        this.showErrorModal('Error deleting vehicle. Please try again.');
      }
    },
    async onUpdateVehicle() {
      this.isLoading = true;

      const updatedVehicle = await updateVehicle({
        id: this.vehicleInfo.id as string,
        title: this.vehicleInfo.title,
        images: this.vehicleInfo.images,
        mileage: Number(this.vehicleInfo.mileage),
        color: this.vehicleInfo.color,
        dateOfLicensing: this.vehicleInfo.dateOfLicensing,
        serviceHistory: this.vehicleInfo.serviceHistory,
        stockCode: this.vehicleInfo.stockCode,
        make: this.vehicleInfo.make,
        model: this.vehicleInfo.model,
        bodyType: this.vehicleInfo.bodyType,
        gearbox: this.vehicleInfo.gearbox,
        gears: Number(this.vehicleInfo.gears),
        fuelType: this.vehicleInfo.fuelType,
        fuelTankCapacity: Number(this.vehicleInfo.fuelTankCapacity),
        doors: Number(this.vehicleInfo.doors),
        seats: Number(this.vehicleInfo.seats),
        price: Number(this.vehicleInfo.price),
        category: this.vehicleInfo.category,
        priority: Number(this.vehicleInfo.priority),
        notes: this.vehicleInfo.notes,
      });

      this.isLoading = false;
      if (updatedVehicle) {
        this.$router.replace(`/vehicle/${this.vehicleInfo.id}`);
      } else {
        this.showErrorModal('Error updating vehicle. Please try again.');
      }
    }
  },
});
</script>

<style scoped lang="scss">
.container {
    margin-top: 50px;
  padding-top: 40px;
  color: #2e323c;
  background: #ffffff;
  position: relative;
  height: 100%;
  justify-content: center;
  width: 100% !important;
  min-height: 100vh;
}

.loadingHeight{
  min-height: 100vh !important;
}

.modal-overlay {
  position: fixed;
  /* Position the modal overlay relative to the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the modal appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-title {
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
}

.heading-info {
  color: #2b2b2b !important;
  font-size: 1.5em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.vButton {
  width: 25ex !important;
  margin: 10px;
}

.form-control {
  border: 1px solid #cfd1d8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 2px;
  border-radius: 5px;
  font-size: .825rem;
  background: #ffffff;
  color: #2e323c;
  width: 70%;
  text-align: center;
}

.note-form-control {
  border: 1px solid #cfd1d8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 2px;
  border-radius: 5px;
  font-size: .825rem;
  background: #ffffff;
  color: #2e323c;
  width: 100% !important;
  text-align: left !important;
  min-height: 5em !important;
}

.form-group {
  display: flex;
  margin-top: 5px;
}

.card {
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  margin-bottom: 1rem;
}

.input-wrapper {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%
}

.input-multi {
  width: 100%
}

.form-label {
  width: 25ex;
  text-align: left;
}


/*.small-image-container {
  min-width: 10em;
  max-width: 57em;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: 1px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
}*/

.image-scroll {
  display: flex;
}

/*.image-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: 1px !important;
}*/

.image-container {
  position: relative;
  display: inline-block;
  margin: 0.5em;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
  min-width: fit-content;
  height: 7em !important;
  text-align: right;
  /* Align the "X" to the right */
  overflow: hidden;
  /* Hide the overflow of the "X" icon */
  position: relative;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(255, 10, 10);
  color: white;
  border-radius: 0 10px 0 0;
  /* Rounded corner for the "X" icon */
  padding: 0.2em 0.4em;
  cursor: pointer;
}

.small-image {
  min-width: 7em !important;
  height: 7em !important;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}

.small-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
}

.plus-icon {
  font-size: 1.5em;
  transition: 0.2s ease;
}

.plus-icon:hover {
  font-size: 1.7em;
  color: blue;
}

::-webkit-scrollbar {
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.vCloss {
  background-color: #fff;
  border-radius: 10px;
}

.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.required {
  color: red;
  margin-left: 4px;
}

.modal-title-error{
  color: red;
  margin-top: 5px;
}

.modal-message{
  color: #1a1a1a;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.btn-success-delete{
  width: 5em
}

.success-delete-div{
  min-width: 15em;
}
</style>