<template>
    <div v-if="!isLoading" class="container mt-4">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
            <div v-for="(vehicle, index) in vehicles" :key="index" class="col">
                <div class="card h-100 shadow-sm" @mouseover="hoveredIndex = index" @mouseout="hoveredIndex = -1">
                    <!--<div class="image-container">
                        <router-link :to="'vehicle/' + 5">
                            <img :src="vehicle.image" class="card-img-top" alt="Vehicle Image">
                        </router-link>
                        <i class="fa fa-heart heart-icon"></i>
                    </div>-->
                    <div class="image-container">
                        <i class="fa fa-chevron-left arrow left-arrow" @click="prevImage(index)"></i>

                        <router-link :to="'vehicle/' + vehicle.id">
                            <img :src="vehicle.images[vehicle.imageIndex]" class="card-img-top" alt="Vehicle Image">
                        </router-link>

                        <i class="fa fa-chevron-right arrow right-arrow" @click="nextImage(index)"></i>
                        <!--<i class="fa fa-heart heart-icon"></i>-->
                    </div>

                    <div class="card-body d-flex flex-column">
                        <div>
                            <h5 class="card-title vehicle-price">{{ vehicle.price }}</h5>
                            <p :class="isMobile() ? 'vehicle-name-mobile' : 'vehicle-name'" :title="vehicle.title">
                                {{ vehicle.title }}
                            </p>
                        </div>
                        <br>
                        <div class="d-flex justify-content-between">
                            <p class="card-text text-muted">{{ vehicle.mileage }} km</p>
                            <button class="btn btn-whatsapp" @click="onEnquire(vehicle.id)">
                                <i class="fab fa-whatsapp"></i> Enquire
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Pagination -->
        <nav class="d-flex justify-content-center mt-4" v-if="showPaginationButton()">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="goToPage(currentPage - 1)">
                        <i class="fa fa-chevron-left"></i>
                    </button>
                </li>
                <li v-for="pageNumber in totalPages" :key="pageNumber" class="page-item"
                    :class="{ active: currentPage === pageNumber }">
                    <button class="page-link" @click="goToPage(pageNumber)">{{ pageNumber }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="goToPage(currentPage + 1)">
                        <i class="fa fa-chevron-right"></i>
                    </button>
                </li>
            </ul>
        </nav>

        <!-- Load More Button -->
        <div class="text-center mt-3" v-if="showLoadMoreButton()">
            <button class="btn btn-load-more" @click="loadMore">Load More</button>
        </div>

        <div v-if="!showPaginationButton()" class="no-Cars">
            <h2 class="no-Cars-Header">Sorry, Cars Not In Stock</h2>
        </div>
    </div>

    <div class="text-center loading-icon" v-else-if="isLoading">
        <!-- Use v-if to conditionally display the loading icon -->
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script lang="ts">
import { fetchVehicles } from '@/api/vehicle';
import { defineComponent } from 'vue';
import { Vehicle } from '@/api/vehicle';
import { formatNumberWithSpaces, redirectToWhatsApp } from '@/util/common';
import { isMobileDevice } from '@/common/commonFunctions';


export default defineComponent({
    name: 'Vehicles',
    data() {
        return {
            vehicles: [//these are the vehicles being displayed
                {
                    id: 'testId',
                    images: [
                        'https://img.autotrader.co.za/25194751/Crop800x600',
                        'https://img.autotrader.co.za/25194752/Crop800x600',
                        'https://img.autotrader.co.za/25194754/Crop800x600'
                    ],
                    price: 'R2 000 000',
                    title: 'BMW M3',
                    mileage: '5,000',
                    imageIndex: 0,
                    priority: 5
                }
            ],
            allVehicles: [] as Vehicle[],// this contains all the vehicles
            hoveredIndex: -1,
            currentPage: 1,
            itemsPerPage: 16,
            isLoading: true,
            totalPages: 1,
            searchedItems: [] as any[],
            isSearchResult: false,
            filterItems: [] as any[],
            isFilterResult: false,
            store: this.$store,
            isSortOn: false,
        };
    },
    props: {
        searchValue: String,
        categoryValue: String
    },
    methods: {
        getVehiclesByPage(pageNumber: number, allVehicles: Vehicle[]) {
            // Calculate the start and end indexes for the requested page
            const startIndex = (pageNumber - 1) * this.itemsPerPage;
            const endIndex = pageNumber * this.itemsPerPage;

            // Extract the items for the requested page
            return (allVehicles).slice(startIndex, endIndex);
        },
        gotovehicle() {
            // TODO: Navigate to vehicle details page
        },
        goToPage(pageNumber: number): void {
            if (this.totalPages <= 0) {
                this.totalPages = 1;
            }

            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                let allVehicles;

                // if multiple items true
                if (this.isSearchResult && this.isFilterResult) {
                    this.isFilterResult = false;
                }

                // filter items
                if (this.isSearchResult) {
                    this.totalPages = Math.ceil(this.searchedItems.length / this.itemsPerPage);
                    allVehicles = this.searchedItems;
                } else if (this.isFilterResult) {
                    this.totalPages = Math.ceil(this.filterItems.length / this.itemsPerPage);
                    allVehicles = this.filterItems;
                } else {
                    this.totalPages = Math.ceil(this.allVehicles.length / this.itemsPerPage);
                    allVehicles = this.allVehicles;
                }

                if (this.isSortOn) {
                    allVehicles = this.sortVehicles(allVehicles);
                }
                const vehiclesOnPage = this.getVehiclesByPage(this.currentPage, allVehicles);
                this.vehicles = [];
                for (const v of vehiclesOnPage) {
                    this.vehicles.push({
                        id: v.id as string,
                        images: v.images,
                        price: `R${formatNumberWithSpaces(v.price)}`,
                        title: v.title,
                        mileage: `${formatNumberWithSpaces(v.mileage)}`,
                        priority: v.priority as number,
                        imageIndex: 0
                    });
                }
                this.scrollToTop();// scroll to top
            }
        },
        isMobile() {
            return isMobileDevice();
        },
        loadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.goToPage(this.currentPage);
            }
        },
        prevImage(index: number) {
            if (this.vehicles[index].imageIndex > 0) {
                this.vehicles[index].imageIndex--;
            } else {
                this.vehicles[index].imageIndex = this.vehicles[index].images.length - 1;
            }
        },
        nextImage(index: number) {
            if (this.vehicles[index].imageIndex < this.vehicles[index].images.length - 1) {
                this.vehicles[index].imageIndex++;
            } else {
                this.vehicles[index].imageIndex = 0;
            }
        },
        onEnquire(vehicleId: string) {
            redirectToWhatsApp(vehicleId);
        },
        displaySearchedItem(value: string) {
            this.searchedItems = this.allVehicles.filter((vehicle) => {
                return vehicle.title.toLowerCase().includes(value.toLowerCase());
            });
            this.goToPage(1);
        },
        sortVehiclesByPriority() {
            this.allVehicles.sort((a, b) => {
                return (a.priority as number) - (b.priority as number);
            });
        },
        displayCategoryItem(value: string) {
            this.filterItems = this.allVehicles.filter((vehicle) => {
                if (vehicle.category) {
                    return vehicle.category.toLowerCase() === value.toLowerCase();
                }
            });
            this.goToPage(1);
        },
        showLoadMoreButton(): boolean {
            return (
                this.currentPage < this.totalPages && // There are more pages to load
                this.vehicles.length > 0 // There are vehicles to show
            );
        },
        showPaginationButton(): boolean {
            return (
                this.vehicles.length > 0 // There are vehicles to show
            );
        },
        applyFilters() {
            const minPrice = this.$store.getters['vehicle/getMinPrice'];
            const maxPrice = this.$store.getters['vehicle/getMaxPrice'];
            const mileage = this.$store.getters['vehicle/getMileage'];
            const year = this.$store.getters['vehicle/getYear'];
            this.filterItems = this.allVehicles.filter((vehicle) => {
                // if price filter is provided
                let shouldReturn = true;
                if (minPrice && maxPrice) {
                    if (vehicle.price <= maxPrice && vehicle.price >= minPrice) {
                        // return vehicle;
                    } else {
                        shouldReturn = false;
                    }
                }

                // if mileage filter is provided
                if (mileage) {
                    if (vehicle.mileage <= mileage) {
                        // return vehicle;
                    } else {
                        shouldReturn = false;
                    }
                }

                // if year is provided
                if (year) {
                    if (vehicle.dateOfLicensing === year.toString()) {
                        // return vehicle;
                    } else {
                        shouldReturn = false;
                    }
                }

                if (shouldReturn) {
                    return vehicle;
                }
            });
            this.goToPage(1);
        },

        sortVehicles(vehicles: Vehicle[]) {
            const priceSort = this.$store.getters['vehicle/getPriceSort'];
            const kmSort = this.$store.getters['vehicle/getKmSort'];
            const yearSort = this.$store.getters['vehicle/getYearSort'];

            let sortedVehicles = [...vehicles];
            if (priceSort === 'lowestToHighest') {
                sortedVehicles = vehicles.slice().sort((a, b) => a.price - b.price);
            } else if (priceSort === 'highestToLowest') {
                sortedVehicles = vehicles.slice().sort((a, b) => b.price - a.price);
            }

            if (kmSort === 'lowestToHighest') {
                sortedVehicles = vehicles.slice().sort((a, b) => a.mileage - b.mileage);
            } else if (kmSort === 'highestToLowest') {
                sortedVehicles = vehicles.slice().sort((a, b) => b.mileage - a.mileage);
            }

            if (yearSort === 'oldestToNewest') {
                sortedVehicles = vehicles.slice().sort((a, b) => {
                    if (a.dateOfLicensing && b.dateOfLicensing) {
                        const aYear = parseInt(a.dateOfLicensing);
                        const bYear = parseInt(b.dateOfLicensing);
                        if (isNaN(aYear) || isNaN(bYear)) {
                            return 0;
                        }

                        return aYear - bYear;
                    } else {
                        return 0;
                    }
                });
            } else if (yearSort === 'newestToOldest') {
                sortedVehicles = vehicles.slice().sort((a, b) => {
                    if (a.dateOfLicensing && b.dateOfLicensing) {
                        const aYear = parseInt(a.dateOfLicensing);
                        const bYear = parseInt(b.dateOfLicensing);
                        if (isNaN(aYear) || isNaN(bYear)) {
                            return 0;
                        }

                        return bYear - aYear;
                    } else {
                        return 0;
                    }
                });
            }
            return sortedVehicles;
        },

        onFilterChange(newValue: boolean | number, oldValue: boolean | number) {
            if (newValue === true || typeof newValue === 'number') {
                this.isFilterResult = true;
                this.applyFilters();
            } else {
                this.isFilterResult = false;
                this.filterItems = [];
                this.goToPage(1);
            }
        },

        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    },
    async mounted() {
        const allVehicles = await fetchVehicles({});
        if (allVehicles) {
            this.allVehicles = allVehicles;
            this.sortVehiclesByPriority();
        } else {
            this.$router.replace("/error");
            console.error('Failed to fetch vehicles');
            return;
        }
        this.totalPages = Math.ceil(this.allVehicles.length / this.itemsPerPage);
        this.vehicles = [];
        if (this.allVehicles) {
            this.isLoading = false;
            this.goToPage(this.currentPage);
        } else {
            this.$router.replace("/error");
            console.error('Failed to fetch vehicles');
        }
    },
    computed: {
        filterOn(): boolean | number {
            return this.$store.getters['vehicle/getFilterOn'];
        },
        sortOn(): boolean | number {
            return this.$store.getters['vehicle/getSortOn'];
        }
    },
    watch: {
        searchValue(newValue, oldValue) {
            if (newValue !== undefined && newValue !== '') {
                this.isSearchResult = true;
                this.displaySearchedItem(newValue)
            } else {
                this.isSearchResult = false;
                this.goToPage(1);
            }
        },
        isSearchResult(newValue, oldValue) {
            if (newValue === false) {
                this.goToPage(1);
            }
        },

        categoryValue(newValue, oldValue) {
            if (newValue !== undefined && newValue !== '') {
                this.isFilterResult = true;
                this.displayCategoryItem(newValue)
            } else {
                this.isFilterResult = false;
                this.filterItems = [];
                this.goToPage(1);
            }
        },
        isFilterResult(newValue, oldValue) {
            if (newValue === false) {
                this.goToPage(1);
            }
        },
        filterOn: {
            handler: 'onFilterChange',
            immediate: true,
        },
        sortOn(newValue, oldValue) {
            if (newValue === true || typeof newValue === 'number') {
                this.isSortOn = true;
            } else {
                this.isSortOn = false;
            }
            this.goToPage(1);
        }
    }
})
</script>

<style scoped lang="scss">
.card {
    transition: transform 0.3s, box-shadow 0.5s;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fff;
    border-radius: 10px !important;
}

.card-body {
    justify-content: left;
    text-align: left;
    padding-left: 25px;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border-color: rgb(0, 49, 128);
    background-color: #fafdff;
}

// .card-img-top {
//     height: 200px;
//     /* Set the desired image height */
//     object-fit: cover;
// }

.card-text.text-muted.align-self-end {
    position: absolute;
    bottom: 1em;
    right: 0.6em;
}

.card-img-top {
    height: 250px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.heart-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: rgb(255, 255, 255, 0.7);
    font-size: 1.5rem;
    border: 1px solid dark;
    padding: 4px;
    border-radius: 50%;
    transition: 0.2s ease;
}

.heart-icon:hover,
heart-icon:focus {
    transform: translateY(-2px);
    color: rgba(226, 24, 24, 0.95);
}

.btn-enquire {
    background-color: transparent;
    border: 1px solid rgb(0, 49, 128);
    color: rgb(0, 49, 128);
    padding: 0.4em 1em;
    border-radius: 10px;
    transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
}

.btn-enquire:hover {
    color: white;
    background-color: rgb(0, 67, 175);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    outline: none;
}

.vehicle-price {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 1.6em;
    color: rgb(43, 115, 182);
}

.vehicle-name {
    color: #585858;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vehicle-name-mobile {
    color: #585858;
    font-weight: bold;
}

.pagination .page-item .page-link {
    color: rgb(0, 49, 128);
    color: rgb(0, 49, 128);
}

.pagination .page-item.active .page-link {
    color: rgb(0, 49, 128);
    color: white;
}

.pagination .page-item.disabled .page-link {
    color: #999;
    pointer-events: none;
}


.btn-load-more {
    background-color: white;
    color: rgb(0, 49, 128);
    color: rgb(0, 49, 128);
    transition: background-color 0.2s, color 0.2s;
}

.btn-load-more:hover {
    background-color: rgb(0, 49, 128);
    color: white;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
    padding: 5px;
    transition: 0.2s ease;
}

.left-arrow:hover {
    font-size: 26px;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
    padding: 5px;
    transition: 0.2s ease;
}

.right-arrow:hover {
    font-size: 26px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.btn-whatsapp {
    background: transparent;
    border: 2px solid #25d366;
    border-radius: 5px;
    color: #25d366;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s;

    i {
        margin-right: 5px;
    }

    &:hover {
        background-color: #25d366;
        color: #fff;
    }
}


.no-Cars {
    margin-top: 7em;
}

.no-Cars-Header {
    font-size: 2em;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(43, 115, 182);
}
</style>