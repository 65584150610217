<!-- <template>
    <div>
      <NavBar />

      <div :class="{ 'page-container': !isMobile(), 'page-container-mobile': isMobile() }">
        <main class="content">
          <div class="card">
            <div class="card-header">FJ Motors Gallery</div>
            <div class="image-container">
              <img :class="{ 'gallery-image': !isMobile(), 'gallery-image-mobile': isMobile() }" src="../assets/fjm1.jpg">
              <img :class="{ 'gallery-image': !isMobile(), 'gallery-image-mobile': isMobile() }" src="../assets/fjm2.jpg">
              <img :class="{ 'gallery-image': !isMobile(), 'gallery-image-mobile': isMobile() }" src="../assets/fjm3.jpg">
              <img :class="{ 'gallery-image': !isMobile(), 'gallery-image-mobile': isMobile() }" src="../assets/fjm4.jpg">
            </div>
          </div>
        </main>
        <FooterComponent />
      </div>
    </div>
  </template>

  <script lang="ts">
  import { defineComponent } from 'vue';
  import NavBar from '@/components/Navbar.vue';
  import Footer from '@/components/Footer.vue';
  import { isMobileDevice } from '@/common/commonFunctions';

  export default defineComponent({
    name: 'ContactPage',
    components: {
      NavBar,
      FooterComponent: Footer,
    },
    methods: {
        isMobile() {
        return isMobileDevice();
        },
    }
  });
  </script>

  <style scoped lang="scss">
  .page-container {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .page-container-mobile {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -120px;
  }

  .card {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }

  .card-header {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 49, 128);
  }

  .image-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    background-color: #f0f0f0;

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 5px;
    }
    scrollbar-width: thin;
    scrollbar-color: #aaa #f0f0f0;
    }

    .gallery-image {
    width: 100%;
    min-width: 960px;
    min-height: 560px;
    object-fit: cover;
    margin-top: 2px;
    margin-right: 10px;
    border-radius: 10px;
    }

    .gallery-image-mobile{
    width: 100%;
    min-width: 25em;
    min-height: 20em;
    object-fit: cover;
    margin-top: 2px;
    margin-right: 10px;
    border-radius: 10px;
    }
  </style>
   -->

   <template>
    <div class="page-container">
      <NavBar class="mb-3"/>
        <main class="content">
        <h1 class="fj-heading">FJ Motors Gallery</h1>
        <div class="gallery">
          <div v-for="(image, index) in images" :key="index" class="gallery-item">
            <img :src="require(`@/assets/${image}`)" alt="Gallery Image" class="img-fluid" />
          </div>
        </div>
        </main>
      <FooterComponent />
    </div>
  </template>

  <script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

  export default defineComponent({
    name: 'GalleryPage',
    components: {
      NavBar,
      FooterComponent: Footer,
    },
    data() {
      return {
        images: ['fjm1.jpg', 'fjm2.jpg', 'fjm3.jpg', 'fjm4.jpg'],
      };
    },
  });
  </script>

<style scoped>
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -5px -5px 20px -5px; /* Top margin added */
}

.gallery-item {
  flex: 0 0 calc(50% - 10px); /* 50% width with margin */
  margin: 5px;
}

@media (max-width: 767px) {
  .gallery-item {
    flex: 0 0 calc(100% - 10px); /* 100% width with margin on smaller screens */
  }
}
.page-container {
  min-height: 100vh;
  /* Ensure the container takes up at least the viewport height */
}
.content {
  margin: 10px;
  margin-top: 85px;
}

.fj-heading{
  padding: 10px;
  text-align: left;
  font-size: 2em;
  font-weight: bold;
  color: rgb(0, 49, 128);
}
</style>
