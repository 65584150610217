import axios from "axios";
import { API_URL } from "./common";
// import https from 'https';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
// const agent = new https.Agent({
//     rejectUnauthorized: false
//   });

export type User = {
    id?: string;
    name?: string;
    email: string;
};

const loginQuery = `query Login ($email: String, $password: String) {
    login (email: $email, password: $password) {
        id
        name
        email
        token
    }
}`;

const getUserFromClaimsQuery = `query GetUserFromClaims {
    getUserFromClaims {
        id
        name
        email
    }
}`;

const updatePasswordMutation = `mutation UpdatePassword($email: String, $currentPassword: String, $newPassword: String) {
    updatePassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword)
  }`;

const updateUserMutation = `mutation UpdateUser($name: String, $email: String) {
    updateUser(name: $name, email: $email) {
      email
      id
      name
    }
  }`;

export const login = async (args: { email: string, password: string }) => {
    const response = await axios.post(API_URL, {
        query: loginQuery,
        variables: { email: args.email, password: args.password },
        // httpsAgent: agent
    });

    if (response.data.errors) {
        return undefined;
    }

    const res = response.data.data;
    if (res) {
        if (res.login) {
            localStorage.setItem('token', res.login.token);
        }
        return {
            id: res.login.id,
            name: res.login.name,
            email: res.login.email,
        } as User;
    } else {
        return undefined;
    }
};

export const getUserFromClaims = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        return undefined;
    }

    const result = await axios.post(API_URL, {
        query: getUserFromClaimsQuery,
        // httpsAgent: agent
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (result.data.errors) {
        return undefined;
    }

    const response = result.data.data;
    if (response) {
        return {
            id: response.getUserFromClaims.id,
            name: response.getUserFromClaims.name,
            email: response.getUserFromClaims.email,
        } as User;
    } else {
        return undefined;
    }
}

export const updatePassword = async (args: { email: string, currentPassword: string, newPassword: string }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return undefined;
    }

    const response = await axios.post(API_URL, {
        query: updatePasswordMutation,
        variables: args,
        // httpsAgent: agent
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    if (response.data.errors) {
        return undefined;
    }

    const res = response.data.data;
    if (res) {
        return res.updatePassword as boolean;
    }
    return undefined;
};

export const updateUser = async (args: { name?: string, email?: string }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return undefined;
    }

    const response = await axios.post(API_URL, {
        query: updateUserMutation,
        variables: args,
        // httpsAgent: agent
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    if (response.data.errors) {
        return undefined;
    }

    const res = response.data.data;
    if (res) {
        return {
            id: res.updateUser.id,
            name: res.updateUser.name,
            email: res.updateUser.email,
        } as User;
    }
    return undefined;
};