<template>
  <div class="filter-component">
    <button class="filter-icon" @click="toggleDropdown"> {{ filterTitle }}
      <i class="fas fa-filter"></i>
    </button>
    <div v-if="showDropdown" class="dropdown">
      <div class="filter-group" v-for="(filter, index) in filters" :key="index">
        <h4 class="filter-title" @click="toggleFilter(filter)">
          {{ filter.title }}
          <i
            class="dropdown-arrow fas"
            :class="{ 'fa fa-chevron-up': filter.show, 'fa fa-chevron-down': !filter.show }"
          ></i>
        </h4>
        <div class="filter-content" v-show="filter.show">
          <component :is="filter.component" v-model="filter.value" :min-price="minPrice" :max-price="maxPrice" />
        </div>
      </div>
      <hr/>
      <div class="apply-filters-wrapper">
        <button class="btn-filter" @click="applyFilters">Apply Filters
          <i class="fas fa-filter"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PriceFilter from './filters/PriceFilter.vue';
import MileageFilter from './filters/MileageFilter.vue';
import ModelYearFilter from './filters/ModelYearFilter.vue';

export default defineComponent({
  name: 'FilterSearch',
  components: {
    PriceFilter,
    MileageFilter,
    ModelYearFilter,
  },
  data() {
    return {
      showDropdown: false as boolean,
      filters: [
        { title: 'Price', show: false, component: 'PriceFilter', value: { min: null, max: null } },
        { title: 'Max Mileage', show: false, component: 'MileageFilter', value: { max: null } },
        { title: 'Model Year', show: false, component: 'ModelYearFilter', value: { year: null } },
      ] as any,
      filterTitle: 'Filter',
      minPrice: null,
      maxPrice: null,
      count: 1, // used to trigger apply filter even when it filter is alraedy on
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleFilter(filter: any) {
      filter.show = !filter.show;
    },
    applyFilters() {
      this.showDropdown = false;
      const isFilterOn = this.$store.getters['vehicle/getFilterOn'];
      this.$store.commit('vehicle/SET_FILTER_ON', this.count++);
      // this.$store.dispatch('vehicle/applyFilters');
    },
  },

  mounted() {
      if (window.innerWidth < 500) {
          this.filterTitle = '';
      }
  },
});
</script>

<style scoped lang="scss">
.filter-component {
  position: relative;
  display: inline-block;
  z-index: 10 !important;
}

.filter-icon {
  background-color: transparent;
  border: 1px solid rgb(0, 49, 128);
  color: rgb(0, 49, 128);
  padding: 6px 12px;
  border-radius: 4px;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  transform: translateY(0);
}

.filter-icon:hover,
.filter-icon:focus {
  color: white;
  background-color: rgb(0, 67, 175);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}


.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  max-height: 50em;
  max-width: 20em;
  width: 15em;
  z-index: 10px !important;
  border-radius: 5px;
  border: 1px solid #d3d3d3
}


.filter-group {
  margin-bottom: 1em;
  margin-top: 1em;
    z-index: 10px !important;

}

.filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.dropdown-arrow {
  font-size: 12px;
  transition: transform 0.3s;
}
.fa-chevron-up {
  transform: rotate(180deg);
}

.apply-filters-wrapper {
  display: flex;
  justify-content: flex-end;
}

.btn-filter{
  background: transparent;
  color: rgb(0, 67, 175);
  border: 1px solid rgb(0, 67, 175);
  border-radius: 5px;
  padding: 7px 7px;
  font-size: 0.9em;
  margin-right: 0.5em;
  margin-top: -5px;
  transition: 0.2s ease;
}

.btn-filter:hover,
.btn-filter:focus{
  color: rgb(0, 81, 212);
  box-shadow: 0px 0px 5px rgba(54, 133, 207, 0.7);
}

</style>
