import { Module } from 'vuex';
import { RootState } from './root';

// Define the state type
interface UserState {
  currentUser: any; // Replace 'any' with the actual type of currentUser
}

const userModule: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    getCurrentUser: (state) => state.currentUser,
  },
  mutations: {
    SET_CURRENT_USER: (state, user) => {
      state.currentUser = user;
    },
  },
  actions: {
    setCurrentUser: ({ commit }, user) => {
      commit('SET_CURRENT_USER', user);
    },
  },
};

export default userModule;
