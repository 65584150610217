<template>
  <div>
    <NavBar />
    <div class="page-container">
      <main class="content">
        <div class="container">
          <div class="row gutters">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div class="card h-100">
                <div class="card-body">
                  <div class="account-settings">
                    <div class="user-profile">
                      <div class="user-avatar">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png"
                          alt="Maxwell Admin" />
                      </div>
                      <h5 class="user-name">{{ user.name }}</h5>
                      <h6 class="user-email">{{ user.email }}</h6>
                    </div>
                    <div class="about">
                      <h5>About</h5>
                      <p>
                        Not much
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div class="card h-100">
                <div class="card-body">
                  <div class="row gutters profile-row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-2 text-primary">Update Account</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="fullName">Admin Name</label>
                        <input type="text" class="form-control" id="fullName" placeholder="Enter name" v-model="user.name"
                          required />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="eMail">Email</label>
                        <input type="email" class="form-control" id="eMail" placeholder="Enter email" v-model="user.email"
                          required />
                      </div>
                    </div>
                    <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="phone">Contact 1</label>
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          placeholder="Enter contact number 1"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="website">Contact 2</label>
                        <input
                          type="url"
                          class="form-control"
                          id="website"
                          placeholder="Enter contact number 1"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="website">Instagram</label>
                        <input
                          type="url"
                          class="form-control"
                          id="website"
                          placeholder="Instagram ID"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="website">Facebook</label>
                        <input
                          type="url"
                          class="form-control"
                          id="website"
                          placeholder="Facebook ID"
                        />
                      </div>
                    </div>-->
                  </div>

                  <div class="row gutters profile-row" style="margin-top:30px">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="text-right">
                        <!-- <button
                          type="button"
                          id="submit"
                          name="submit"
                          class="btn btn-secondary pButton"
                        >
                          Discard
                        </button> -->
                        <button type="button" id="submit" name="submit" class="btn btn-primary" @click="onUpdatePersonalDetails">
                          <span v-if="isUpdatingPersonalDetails" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                          Update details
                        </button>
                      </div>
                      <p v-if="personalDetailsStatus !== false"
                        :class="(personalDetailsStatus.status === 'error') ? 'mt-3 text-danger' : 'mt-3 text-success'">{{
                          personalDetailsStatus.message }}</p>
                    </div>
                  </div>
                  <!--<div class="row gutters  profile-row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Address</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="Street">Street</label>
                        <input
                          type="name"
                          class="form-control"
                          id="Street"
                          placeholder="Enter Street"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="ciTy">City</label>
                        <input
                          type="name"
                          class="form-control"
                          id="ciTy"
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="sTate">State</label>
                        <input
                          type="text"
                          class="form-control"
                          id="sTate"
                          placeholder="Enter State"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="zIp">Zip Code</label>
                        <input
                          type="text"
                          class="form-control"
                          id="zIp"
                          placeholder="Zip Code"
                        />
                      </div>
                    </div>
                  </div>-->
                  <hr />
                  <div class="row gutters profile-row">
                    <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Password</h6>
                    </div> -->
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="Street">Current Password</label>
                        <input type="password" class="form-control" id="Street" placeholder="Enter current password"
                          v-model="currentPassword" required />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="zIp">New Password</label>
                        <input type="password" class="form-control" id="zIp" placeholder="Enter new password"
                          v-model="newPassword" required />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="form-group">
                        <label for="sTate">Confirm Password</label>
                        <input type="password" class="form-control" id="sTate" placeholder="Confirm password"
                          v-model="confirmPassword" required />
                      </div>
                    </div>
                  </div>

                  <div class="row gutters profile-row" style="margin-top:30px">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="text-right">
                        <!-- <button
                          type="button"
                          id="submit"
                          name="submit"
                          class="btn btn-secondary pButton"
                        >
                          Discard
                        </button> -->
                        <button type="button" id="submit" name="submit" class="btn btn-primary" @click="onUpdatePassword">
                          <span v-if="isUpdatingPassword" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                          Update password
                        </button>
                      </div>
                      <p v-if="passwordStatus !== false"
                        :class="(passwordStatus.status === 'error') ? 'mt-3 text-danger' : 'mt-3 text-success'">{{
                          passwordStatus.message }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
    
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { updatePassword, updateUser } from "@/api/user";

export default defineComponent({
  name: "ProfileView",
  components: {
    NavBar,
    FooterComponent: Footer,
  },
  data() {
    return {
      user: {} as any,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordStatus: false as any,
      isUpdatingPassword: false,
      personalDetailsStatus: false as any,
      isUpdatingPersonalDetails: false,
    };
  },

  mounted() {
    this.user = this.$store.getters['user/getCurrentUser'];
    // if (!this.user) {
    //   this.$router.replace('/');
    //   return;
    // }
  },

  methods: {
    async onUpdatePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.passwordStatus = { status: 'error', message: 'passwords do not match' };
        return;
      }

      this.isUpdatingPassword = true;
      const updatePasswordStatus = await updatePassword({
        email: this.user.email,
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      });

      if (updatePasswordStatus) {
        this.passwordStatus = { status: 'success', message: 'password updated successfully' };
      } else {
        this.passwordStatus = { status: 'error', message: 'password update failed' };
      }
      this.isUpdatingPassword = false;

    },
    async onUpdatePersonalDetails() {
      this.isUpdatingPersonalDetails = true;
      const updatedUser = await updateUser({ name: this.user.name, email: this.user.email });
      this.isUpdatingPersonalDetails = false;
      if (updatedUser) {
        this.personalDetailsStatus = { status: 'success', message: 'personal details updated successfully' };
      } else {
        this.personalDetailsStatus = { status: 'error', message: 'personal details update failed' };
      }
    }
  }
});
</script>

<style scoped lang="scss">
.page-container{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content{
  flex: 1;
  margin-top: 20px;
}

.account-settings .user-profile {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  text-align: center;
}

.account-settings .user-profile .user-avatar {
  margin: 0 0 1rem 0;
}

.account-settings .user-profile .user-avatar img {
  width: 90px;
  height: 90px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.account-settings .user-profile h5.user-name {
  margin: 0 0 0.5rem 0;
}

.account-settings .user-profile h6.user-email {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #9fa8b9;
}

.account-settings .about {
  margin: 2rem 0 0 0;
  text-align: center;
}

.account-settings .about h5 {
  margin: 0 0 15px 0;
  color: #007ae1;
}

.account-settings .about p {
  font-size: 0.825rem;
}

.form-control {
  border: 1px solid #cfd1d8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 0.825rem;
  background: #ffffff;
  color: #2e323c;
}

.form-group {
  margin-top: 10px;
}

.profile-row {
  margin-top: 0.01em;
}

.card {
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  margin-bottom: 1rem;
  transition: 0.2s ease;
}

.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.pButton {
  margin: 10px;
  width: 6em;
}
</style>
