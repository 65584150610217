
export function formatNumberWithSpaces(number: number) {
    // Convert the number to a string
    const numberString = number.toString();

    // Use regular expressions to insert spaces
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return formattedNumber;
}

export function redirectToWhatsApp(id?: string) {
    const phoneNumber = '27835148628';
    const message = `https://fjmotor.co.za?id=${id}
Hi, Is this still available?`
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open the WhatsApp chat URL in a new tab or window
    window.open(whatsappUrl, '_blank');
  }

export function camelCaseToSpacedString(input: string) {
    return input.replace(/([A-Z])/g, ' $1').trim().split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function extractUUIDFromBlobUrl(blobUrl: string) {
    const startIndex = blobUrl.indexOf("vehicle/") + "vehicle/".length;
      const endIndex = blobUrl.indexOf("?");
      if (startIndex !== -1 && endIndex !== -1) {
        const uuid = blobUrl.slice(startIndex, endIndex);
        return uuid;
      }
      return undefined;
}

export async function cropImage(file: File, cropWidth: number, cropHeight: number): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      const context = canvas.getContext('2d');
      if (!context) {
        reject(new Error('Canvas context is not supported.'));
        return;
      }

      context.drawImage(image, 0, 0, cropWidth, cropHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], file.name, { type: file.type });
          resolve(croppedFile);
        } else {
          reject(new Error('Error cropping the image.'));
        }
      }, file.type);
    };
  });
}
