<!-- MileageFilter.vue -->
<template>
  <div>
    <div class="input-container form-mileage">
      <label class="form-label small-label">Max Mileage (kms)</label>
      <input type="number" class="form-control" v-model="mileage" @input="updateMileage" />
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MileageFilter',
  props: ['maxMileage'],
  data() {
    return {
      mileage: this.$store.getters['vehicle/getMileage'],
    };
  },
  methods: {
    updateMileage() {
      this.$store.commit('vehicle/SET_MILEAGE', this.mileage);
    },
  }
});
</script>

<style scoped lang="scss">
.input-container {
  position: relative;
}

.small-label {
  font-size: 0.8em;
  position: absolute;
  top: -10px;
  left: 5px;
  background: #fff;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
}

.form-control {
  padding-top: 0.5em;
  width: 100%;
  /* Ensure the input field takes up the full width */
}

.form-mileage {
  margin-top: 10px;
}
</style>
