<template>
    <footer class="bg-blue text-white py-4 mt-4">
        <div class="container text-center">
            <div class="mb-3">
                <a href="https://www.instagram.com/fjmotors_randfontein/?hl=en" target="blank" class="text-white me-3 footer-icon">
                    <i class="fab fa-instagram fa-lg"></i> <!-- Font Awesome Instagram icon -->
                </a>
                <a href="https://www.facebook.com/Fjmotors01" class="text-white me-3 footer-icon">
                    <i class="fab fa-facebook fa-lg"></i> <!-- Font Awesome Facebook icon -->
                </a>
                <a href="#" class="text-white footer-icon">
                    <i class="fab fa-twitter fa-lg"></i> <!-- Font Awesome Twitter icon -->
                </a>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <ul class="list-unstyled">
                        <li><router-link to="/contact" class="text-white text-decoration-none footer-link">Contact Us</router-link></li>
                        <li><router-link to="/contact" class="text-white text-decoration-none footer-link">About</router-link></li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul class="list-unstyled">
                        <li><router-link to="/contact" class="text-white text-decoration-none footer-link">Our Location</router-link></li>
                        <li><router-link to="/gallery" class="text-white text-decoration-none footer-link">Gallery</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Footer',
})
</script>

<style scoped>
.footer-link:hover,
.footer-icon:hover {
    color: #ffffff !important;  /* Use the Bootstrap warning color */
    transition: color 0.3s;
}

.bg-blue{
    background-color: rgb(0, 36, 95) !important;
    transition: 0.2s  ease;
}

.bg-blue:hover {
    background-color: rgb(0, 36, 95) !important;
}

</style>