import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VehicleView from '../views/VehicleView.vue'
import LoginView from '../views/LoginView.vue'
import ProfileView from '../views/ProfileView.vue'
import ContactView from '../views/ContactView.vue'
import AddVehicleView from '../views/AddVehicleView.vue'
import EditVehicleView from '../views/EditVehicleView.vue'
import ErrorView from '../views/ErrorView.vue'
import GalleryView from '../views/GalleryView.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/vehicle/:id',
    component: VehicleView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/addVehicle',
    name: 'addVehicle',
    component: AddVehicleView
  },
  {
    path: '/editVehicle/:id',
    component: EditVehicleView
  },
  {
    path: '/error',
    component: ErrorView
  },
  {
    path: '/gallery',
    component: GalleryView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
