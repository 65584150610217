<template>
    <div class="sort-component">
        <button class="sort-icon" @click="toggleDropdown"> {{ sortTitle }}
            <i class="fas fa-sort"></i>
        </button>
        <div v-if="showDropdown" class="dropdown">
            <div class="sort-group">
                <h4 class="sort-title">Price</h4>
                <div class="sort-options">
                    <label class="form-check-label">
                        <input type="radio" name="priceSort" class="form-check-input" v-model="selectedPriceSort"
                            value="highestToLowest"  @input="updatePriceSort('highestToLowest')"/>
                        Highest to lowest
                    </label>
                    <label class="form-check-label">
                        <input type="radio" name="priceSort" class="form-check-input" v-model="selectedPriceSort" style="margin-left: 0.2em;"
                            value="lowestToHighest" @input="updatePriceSort('lowestToHighest')"/>
                        Lowest to highest
                    </label>
                </div>
            </div>
            <hr />
            <div class="sort-group">
                <h4 class="sort-title">Kilometers</h4>
                <div class="sort-options">
                    <label class="form-check-label">
                        <input type="radio" name="kilometersSort" class="form-check-input" v-model="selectedKilometersSort"
                            value="highestToLowest" @input="updateKmSort('highestToLowest')"/>
                        Highest to lowest
                    </label>
                    <label class="form-check-label">
                        <input type="radio" name="kilometersSort" class="form-check-input" v-model="selectedKilometersSort" style="margin-left: 0.2em;"
                            value="lowestToHighest" @input="updateKmSort('lowestToHighest')"/>
                        Lowest to highest
                    </label>
                </div>
            </div>
            <hr />
            <div class="sort-group">
                <h4 class="sort-title">Year</h4>
                <div class="sort-options">
                    <label class="form-check-label">
                        <input type="radio" name="yearSort" class="form-check-input" v-model="selectedYearSort"
                            value="newestToOldest" @input="updateYearSort('newestToOldest')"/>
                        Newest to oldest
                    </label>
                    <label class="form-check-label">
                        <input type="radio" name="yearSort" class="form-check-input" v-model="selectedYearSort"
                            value="oldestToNewest" @input="updateYearSort('oldestToNewest')"/>
                        Oldest to newest
                    </label>
                </div>
            </div>
            <hr/>
            <div class="apply-sort-wrapper" :class="{ 'apply-sort-wrapper-no-reset': !showReset }">
                <button v-if="showReset" class="btn btn-sort-reset" @click="reset">reset</button>
                <button class="btn-sort" @click="applySort">Apply Sorting
                    <i class="fas fa-sort"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isMobileDevice } from '@/common/commonFunctions'

export default defineComponent({
    name: 'SortSearch',
    data() {
        return {
            showDropdown: false,
            selectedPriceSort: this.$store.getters['vehicle/getPriceSort'],
            selectedKilometersSort: undefined as string | undefined,
            selectedYearSort: undefined as string | undefined,
            sortTitle: 'Sort',
            showReset: false,
            count: 1, // used to trigger apply sort even when it sort is alraedy on
        };
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        updatePriceSort(value: string) {
            this.selectedPriceSort = value;
            this.$store.commit('vehicle/SET_PRICE_SORT', this.selectedPriceSort);
            this.showReset = true;
        },
        updateKmSort(value: string) {
            this.selectedKilometersSort = value;
            this.$store.commit('vehicle/SET_KM_SORT', this.selectedKilometersSort);
            this.showReset = true;
        },
        updateYearSort(value: string) {
            this.selectedYearSort = value;
            this.$store.commit('vehicle/SET_YEAR_SORT', this.selectedYearSort);
            this.showReset = true;
        },
        reset() {
            this.selectedPriceSort = undefined;
            this.selectedKilometersSort = undefined;
            this.selectedYearSort = undefined;
            this.$store.dispatch('vehicle/clearSort');
            this.showReset = false;
        },
        applySort() {
            this.showDropdown = false;
            this.$store.commit('vehicle/SET_SORT_ON', this.count++);
        }
    },
    mounted() {
        if (isMobileDevice()) {
            this.sortTitle = '';
        }
    },
    computed: {
        isSortOn(): boolean {
            return this.$store.getters['vehicle/getSortOn'];
        }
    },
    watch: {
        isSortOn(newValue, oldValue) {
            if (newValue === false) {
                this.selectedPriceSort = undefined;
                this.selectedKilometersSort = undefined;
                this.selectedYearSort = undefined;
            }
        }
    }
});
</script>

<style scoped lang="scss">
.sort-component {
    position: relative;
    display: inline-block;
    z-index: 2;
}

.sort-icon {
  background-color: transparent;
  border: 1px solid rgb(0, 49, 128);
  color: rgb(0, 49, 128);
  padding: 0.4em 1em;
  border-radius: 4px;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  transform: translateY(0);
}

.sort-icon:hover,
.sort-icon:focus {
  color: white;
  background-color: rgb(0, 67, 175);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}


.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-height: 26em;
  max-width: 20em;
  width: 15em;
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  border: 1px solid #d3d3d3
}

.dropdown::-webkit-scrollbar {
  width: 4px;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.sort-group {
    margin-bottom: 1em;
    margin-left: -1em;
}

.sort-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: left;
    margin-left: 1.5em;
}

.sort-options {
    display: flex;
    flex-direction: column;
}

.form-check-label {
    margin-bottom: 0.3em;
    cursor: pointer;
}

.form-check-input {
    margin-right: 0.5em;
}

.apply-sort-wrapper {
  display: flex;
  justify-content: space-between;
}

.apply-sort-wrapper-no-reset {
  display: flex;
  justify-content: flex-end;
}


.btn-sort{
  background: transparent;
  color: rgb(0, 67, 175);;
  border: 1px solid rgb(0, 67, 175);
  border-radius: 5px;
  padding: 7px 7px;
  font-size: 0.9em;
  margin-right: 0.5em;
  margin-top: -5px;
  transition: 0.3s ease;
  font-family:Arial, Helvetica, sans-serif
}

.btn-sort:hover{
  color: rgb(0, 81, 212);
  box-shadow: 0px 0px 5px rgba(54, 133, 207, 0.7);
}

.btn-sort-reset {
  /* Add styles for the reset button */
  background: transparent;
  color: rgb(95, 95, 95); /* Set your desired color */
  //border: 1px solid red; /* Set your desired color */
  border-radius: 5px;
  padding: 7px 7px;
  font-size: 0.9em;
  margin-left: 0.5em; /* Adjust the margin as needed */
  margin-top: -5px;
  transition: 0.3s ease;
  font-family: Arial, Helvetica, sans-serif;
}

.btn-sort-reset:hover {
  box-shadow: 0px 0px 5px rgba(95, 95, 95, 0.7); /* Set your desired color */
}
</style>