<template>
  <div>
    <NavBar />

    <div class="page-container">
      <main class="content">
        <div class="container mt-4">
          <div class="row">
            <div class="col-md-8">
              <div class="col-md-12 mb-3">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between header-card">
                    <h6 class="header-text">FJ Motors Location</h6>
                </div>
                <div class="body-card card">
                    <div class="contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3569.2289663839364!2d27.7032883!3d-26.1880281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95bdc88b57898d%3A0xcc8314b111863512!2sFJ%20MOTORS!5e0!3m2!1sen!2sus!4v1664635627160!5m2!1sen!2sus" width="100%" height="500em" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
            </div>

            <div class="col-md-4">
              <div class="card" style="border-radius:10px">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between header-card">
                    <h6 class="header-text">Contact Information</h6>
                </div>
                <div class="card-body">
                  <table>
                      <tr>
                          <td class="label">Email:</td>
                          <td> <a href="mailto:info@fjmotor.co.za">info@fjmotor.co.za</a></td>
                      </tr>
                      <tr>
                          <td class="label">Phone:</td>
                          <td>+27 83 514 8628</td>
                      </tr>
                      <tr>
                          <td class="label">Instagram:</td>
                          <td><a href="https://www.instagram.com/fjmotors_randfontein/?hl=en" target="_blank">@fjmotors_randfontein</a></td>
                      </tr>
                      <tr>
                          <td class="label">TikTok:</td>
                          <td><a href="https://www.tiktok.com/@fj.motors?_t=8hBfwjaGIAn&_r=1" target="_blank">@fj.motors</a></td>
                      </tr>
                      <tr>
                          <td class="label">Facebook:</td>
                          <td><a href="https://www.facebook.com/Fjmotors01" target="_blank">FJ MOTORS</a></td>
                      </tr>
                  </table>
              </div>
              </div>

              <!--Operating Hours-->
              <div class="card" style="border-radius:10px; margin-top: 20px">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between header-card">
                    <h6 class="header-text">Operating Hours</h6>
                </div>
                <div class="card-body">
                  <table>
                      <tr>
                          <td class="label">Monday:</td>
                          <td>8:00am - 5:15pm</td>
                      </tr>
                      <tr>
                          <td class="label">Tuesday:</td>
                          <td>8:00am - 5:15pm</td>
                      </tr>
                      <tr>
                          <td class="label">Wednesday:</td>
                          <td>8:00am - 5:15pm</td>
                      </tr>
                      <tr>
                          <td class="label">Thursday:</td>
                          <td>8:00am - 5:15pm</td>
                      </tr>
                      <tr>
                          <td class="label">Friday:</td>
                          <td>8:00am - 5:15pm</td>
                      </tr>
                      <tr>
                          <td class="label">Saturday:</td>
                          <td>8:00am - 3:00pm</td>
                      </tr>
                      <tr>
                          <td class="label">Sunday:</td>
                          <td>8:00am - 1:00pm</td>
                      </tr>
                  </table>
              </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'ContactPage',
  components: {
    NavBar,
    FooterComponent: Footer,
  },
});
</script>

<style scoped lang="scss">
.page-container{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content{
  flex: 1;
}

.google-map-container {
  text-align: center;
}

.header-card{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #f7f7f7;
}

.body-card{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.header-text{
    font-size: 1.5em;
    color: #202020;
}

table {
    width: 100%;
    border-collapse: separate;
}

tr,td {
  padding-bottom: 5px;
  text-align: left;
}

.label {
    font-weight: bold;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
}

.contact-link{
    text-decoration: none;
}
</style>
