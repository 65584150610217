import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';
import vehicle from './vehicle';
import { RootState } from './root';

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

export default new Vuex.Store<RootState>({
  modules: {
    user,
    vehicle,
  },
});