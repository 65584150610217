<!-- ModelYearFilter.vue -->
<template>
  <div>
    <div class="input-container form-model">
      <label for="modelYear" class="form-label small-label">Model Year:</label>
      <input id="modelYear" type="number" class="form-control" v-model="year" @input="updateYear" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModelYearFilter',
  props: ['propYear'],
  data() {
    return {
      year: this.$store.getters['vehicle/getYear'],
    };
  },
  methods: {
    updateYear() {
      this.$store.commit('vehicle/SET_YEAR', this.year);
    },
  }
});
</script>

<style scoped lang="scss">
.input-container {
  position: relative;
}

.small-label {
  font-size: 0.8em;
  position: absolute;
  top: -10px;
  left: 5px;
  background: #fff;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
}

.form-control {
  padding-top: 0.5em;
  width: 100%;
}

.form-model{
  margin-top: 10px;
}
</style>

