<template>
  <div>
    <NavBar />

    <div class="page-container">
      <main class="content">
        <div class="error-div">
            <img class="error-image" src="../assets/breakdown.png">
            <h1 class="error-message">Something went wrong!</h1>
            <p class="error-text">Sorry, our site is currently facing some issues, we will be back soon.</p>
        </div>
      </main>
      <FooterComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'ContactPage',
  components: {
    NavBar,
    FooterComponent: Footer,
  },
});
</script>

<style scoped lang="scss">
.page-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  margin-top: -20ex;
}

.error-div{
  border: 1px solid #d3d3d3;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  margin-top: 2em;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); 
}

.error-message {
  color: red;
  text-align: center;
  font-size: 2em;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.error-image{
    width: 40%;
    margin-bottom: 10px;
}

.error-text{
    color: #000;
}
</style>

