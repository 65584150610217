<template>
  <div>
    <NavBar />
    <div class="wrapper">
    <div class="page-container">
      <main class="content">
        <div class="login-container">
          <h2>Login</h2>
          <form action="/submit" method="post" @submit="login">
            <div class="input-group">
              <label for="username">Email:</label>
              <input v-model="email" type="text" id="username" name="username" class="input-field" required>
            </div>
            <div class="input-group">
              <label for="password">Password:</label>
              <input v-model="password" type="password" id="password" name="password" class="input-field" required>
            </div>
            <button type="submit" class="login-button" :disabled="isLoggingIn">
              <span v-if="isLoggingIn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Login
            </button>
            <p class="login-error" v-if="isError">Login failed. email/password incorrect.</p>
          </form>
        </div>
      </main>
    </div>
    <FooterComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import { login } from '@/api/user';

export default defineComponent({
  name: 'LoginView',
  components: {
    NavBar,
    FooterComponent: Footer,
  },
  data() {
    return {
      email: '',
      password: '',
      isError: false,
      isLoggingIn: false,
      intervalId: '' as any
    }
  },
  methods: {
    async login(event: Event) {
      event.preventDefault();

      if (this.isLoggingIn) return;

      this.isLoggingIn = true;
      const user = await login({ email: this.email, password: this.password });
      this.isLoggingIn = false;
      if (!user) {
        this.isError = true;
      } else {
        this.isError = false;
        this.$store.dispatch('user/setCurrentUser', user);
        this.$router.replace('/');
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
    checkLogin() {
      const user = this.$store.getters['user/getCurrentUser'];
      if (user) {
        this.$router.replace('/');
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  },
  onMount() {
    this.intervalId = setInterval(this.checkLogin, 3000);
    const user = this.$store.getters['user/getCurrentUser'];
    if (user) {
      this.$router.replace('/');
      localStorage.setItem('user', JSON.stringify(user));
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
});
</script>

<style scoped lang="scss">
.page-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures the wrapper takes the full viewport height */
}

.login-container {
  width: 23em;
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: 0.2s ease;
  margin-top: 30%;
}

.login-container:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  transform: translateY(-5px);
}

h2 {
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.input-field {
  border-radius: 10px !important;
  transition: 0.2s ease;
}

.input-field:hover,
.input-field:focus {
  border: 1px solid #007bff !important;
  box-shadow: 0px 0px 10px 0px rgba(71, 169, 214, 0.4);
}

.login-error {
  color: red;
}
</style>
