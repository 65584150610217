<template>
  <div class="price-filter">
    <div class="input-container">
      <label class="form-label small-label" for="minPrice">Min:</label>
      <input
        id="minPrice"
        type="number"
        class="form-control"
        v-model="min"
        @input="updateMin"
      />
    </div>
    <div class="input-container">
      <label class="form-label small-label" for="maxPrice">Max:</label>
      <input
        id="maxPrice"
        type="number"
        class="form-control"
        v-model="max"
        @input="updateMax"
      />
    </div>
  </div>
</template>


  <script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PriceFilter',
    props: ['minPrice', 'maxPrice'],
    data() {
      return {
        min: this.$store.getters['vehicle/getMinPrice'],
        max: this.$store.getters['vehicle/getMaxPrice'],
      };
    },
    methods: {
      updateMin() {
        this.$store.commit('vehicle/SET_MIN_PRICE', this.min);
      },
      updateMax() {
        this.$store.commit('vehicle/SET_MAX_PRICE', this.max);
      }
    },
  });
  </script>

<style scoped lang="scss">
.price-filter {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.input-container {
  position: relative;
}

.small-label {
  font-size: 0.8em;
  position: absolute;
  top: -12px;
  left: 5px;
  background: #fff;
}

.form-control {
  padding-top: 0.5em; /* Adjust as needed to create spacing between label and input */
}

.price-filter {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
  font-size: large;
  margin-top: 10px;
}
</style>

