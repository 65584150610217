<template>
  <div>
    <NavBar />

    <div class="container" style="justify-content: center">
      <h2 class="add-title">Add Vehicle</h2>
      <div class="row gutters">
        <div class="card h-100">
          <div class="card-body">
            <div class="row gutters my-card">
              <div>
                <h6 class="mb-2 text-primary heading-info">Basic details</h6>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Title<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.title" type="text" class="form-control" id="fullName"
                      placeholder="Enter title" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Mileage<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.mileage" type="number" class="form-control" id="mileage" min="0"
                      placeholder="Enter mileage" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Make<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.make" type="text" class="form-control" id="make"
                      placeholder="Enter vehicle make" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Model<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.model" type="text" class="form-control" id="model"
                      placeholder="Enter model" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Price<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.price" type="number" class="form-control" id="price" min="0"
                      placeholder="Enter Price" required/>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Year Model<span class="required">*</span>:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.dateOfLicensing" type="text" class="form-control" id="dateLicense"
                      placeholder="Enter year model" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutters">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 class="mt-3 mb-2 text-primary heading-info">
                  Specifications
                </h6>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Colour:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.color" type="text" class="form-control" id="colour"
                      placeholder="Enter colour" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Body Type:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.bodyType" type="text" class="form-control" id="body"
                      placeholder="Enter body type" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Feul Type:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.fuelType" type="text" class="form-control" id="feul"
                      placeholder="Enter feul type" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Feul Tank Capacity:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.fuelTankCapacity" type="number" class="form-control" id="feulSize" min="0"
                      placeholder="Enter feul tank capacity" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Gearbox:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.gearbox" type="text" class="form-control" id="gear" min="0"
                      placeholder="Enter gearbox type" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Gears:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.gears" type="number" class="form-control" id="numGear" min="0"
                      placeholder="Enter number of gears" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Doors:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.doors" type="number" class="form-control" id="doors" min="0"
                      placeholder="Enter number of doors" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Seats:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.seats" type="number" class="form-control" id="seats" min="0"
                      placeholder="Enter number of seats" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Service History:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.serviceHistory" type="text" class="form-control" id="service"
                      placeholder="Enter service history" />
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="fullName" class="form-label">Stock Code:</label>
                  <div class="input-wrapper">
                    <input v-model="vehicle.stockCode" type="text" class="form-control" id="stock"
                      placeholder="Enter stock code" />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="category" class="form-label">Category:</label>
                  <div class="input-wrapper">
                    <select class="form-control" id="category" v-model="vehicle.category">
                      <option value="sedan">Sedan</option>
                      <option value="hatchback">Hatchback</option>
                      <option value="suv">SUV</option>
                      <option value="singleCab">Single Cab</option>
                      <option value="doubleCab">Double Cab</option>
                      <option value="sevenSeater">7 Seater</option>
                      <option value="bus">Bus</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                  <label for="priority" class="form-label">Priority:</label>
                  <div class="input-wrapper">
                    <select class="form-control" id="priority" v-model="vehicle.priority">
                      <option value="1">1 (very high)</option>
                      <option value="2">2 (high)</option>
                      <option value="3">3 (medium)</option>
                      <option value="4">4 (low)</option>
                      <option value="5" selected>5 (very low)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutters">
              <label for="notes" class="form-label">Notes:</label>
                <div class="input-multi">
                  <textarea class="form-control note-form-control" id="notes" placeholder="Enter Notes" v-model="vehicle.notes"></textarea>
                </div>
            </div>
            <div class="row gutters my-card">
              <div>
                <h6 class="mb-2 text-primary heading-info" style="margin-top: 20px">
                  Images
                </h6>
              </div>
              <div class="small-image-wrapper">
                <div class="small-image-container" style="overflow-x: auto; overflow-y: hidden">
                  <div class="image-scroll">
                    <div v-for="(image, index) in vehicle.images" :key="index" :class="{ '': index === imageIndex }"
                      @click="showRemoveConfirmation(index)" class="image-container">
                      <img :src="image" alt="Product Image" :class="activeImageClass(index)" />
                      <span class="remove-icon" @click="showRemoveConfirmation(index)">X</span>
                    </div>
                  </div>

                  <div v-if="images.length === 0" :key="index" class="img-fluid" style="align-items: center">
                    <img :src="noImage" alt="Product Image" :class="activeImageClass(index)" />
                  </div>
                </div>

                <label for="fileInput" class="plus-icon">
                  <input type="file" id="fileInput" ref="fileInput" multiple @change="handleFileUpload"
                    accept="image/jpeg, image/png" style="display: none" />
                  <i class="fas fa-plus"></i>
                </label>
              </div>
            </div>
            <div class="row gutters">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="text-right">
                  <button type="button" id="submit" class="btn btn-primary vButton" @click="addVehicle">Add
                    Vehicle</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Removal Confirmation Modal -->
    <div class="modal-overlay" v-if="isRemoveConfirmationVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageRemovalModalLabel">
              Remove Image
            </h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close"
              @click="closeRemoveConfirmationModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to remove this image?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeRemoveConfirmationModal">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="removeImageConfirmed">
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal-overlay" v-if="isErrorModalVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title-error" id="errorModalLabel">Error</h5>
            <button type="button" class="close vCloss" data-dismiss="modal" aria-label="Close" @click="closeErrorModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body error-message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>


    <FooterComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { isMobileDevice } from "@/common/commonFunctions";
import { Vehicle, createVehicle } from "@/api/vehicle";
// import { BlobServiceClient } from '@azure/storage-blob';
import { containerClient } from '@/api/azureBlob';
import { v4 as uuidv4 } from 'uuid';
import { extractUUIDFromBlobUrl } from "@/util/common";

export default defineComponent({
  name: "VehicleView",
  components: {
    NavBar,
    FooterComponent: Footer,
  },
  data() {
    return {
      isHovering: false,
      imageIndex: 0,
      isRemoveConfirmationVisible: false, // To control the visibility of the remove confirmation dialog
      imageIndexToRemove: null as number | null,
      isErrorModalVisible: false,
      errorMessage: "",
      images: [
        "https://img.autotrader.co.za/26099264/Crop800x600",
        "https://img.autotrader.co.za/26099262/Crop800x600",
        "https://img.autotrader.co.za/26099263/Crop800x600",
        "https://img.autotrader.co.za/26099264/Crop800x600",
        "https://img.autotrader.co.za/26099262/Crop800x600",
        "https://img.autotrader.co.za/26099263/Crop800x600",
        "https://img.autotrader.co.za/26099264/Crop800x600",
        "https://img.autotrader.co.za/26099262/Crop800x600",
        "https://img.autotrader.co.za/26099263/Crop800x600",
        "https://img.autotrader.co.za/26099264/Crop800x600",
      ],
      noImage:
        "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg",
      vehicleInfo: [
        { key: "Mileage:", value: "80000" },
        { key: "Color:", value: "Blue" },
        { key: "Date of first licensing:", value: "2023" },
        { key: "Service history:", value: "unknown" },
        { key: "Stock code: ", value: "123456" },
        { key: "Make:", value: "BMW" },
        { key: "Model:", value: "M3" },
        { key: "Body type:", value: "Sedan" },
        { key: "Gearbox:", value: "Automatic" },
        { key: "Gears:", value: "6" },
        { key: "Feul type:", value: "Petrol" },
        { key: "Feul tank capacity:", value: "59L" },
        { key: "Number of doors:", value: "4" },
        { key: "Number of seats:", value: "4" },
      ] as { key: string; value: string }[],
      selectedImages: [] as File[], // Store selected files here
      vehicle: {
        title: '',
        images: [],
        mileage: 0,
        make: '',
        model: '',
        price: 0,
        dateOfLicensing: '',
        color: '',
        bodyType: '',
        fuelType: '',
        fuelTankCapacity: 0,
        gearbox: '',
        gears: 6,
        doors: 4,
        seats: 5,
        serviceHistory: '',
        stockCode: '',
        notes: '',
        category: 'vehicle',
        priority: 5,
      } as Vehicle,
      title: ''
    };
  },
  async onMount() {
    this.vehicle.title = 'nonsence'
  },
  methods: {
    changeImage(step: any) {
      this.imageIndex =
        (this.imageIndex + step + this.images.length) % this.images.length;
    },
    selectImage(index: any) {
      this.imageIndex = index;
    },
    activeImageClass(index: any) {
      if (index === this.imageIndex) {
        return "img-fluid small-image small-image-selected";
      } else {
        return "img-fluid small-image";
      }
    },
    isMobile() {
      return isMobileDevice();
    },
    removeImage(index: number) {
      const shouldRemove = window.confirm(
        "Are you sure you want to remove this image?"
      );
      if (shouldRemove) {
        this.images.splice(index, 1);
        this.imageIndex = 0;
      }
    },
    async addVehicle() {
      const newVehicle = await createVehicle({
        title: this.vehicle.title,
        images: this.vehicle.images,
        mileage: Number(this.vehicle.mileage),
        make: this.vehicle.make,
        model: this.vehicle.model,
        price: Number(this.vehicle.price),
        dateOfLicensing: this.vehicle.dateOfLicensing,
        color: this.vehicle.color,
        bodyType: this.vehicle.bodyType,
        fuelType: this.vehicle.fuelType,
        fuelTankCapacity: Number(this.vehicle.fuelTankCapacity),
        gearbox: this.vehicle.gearbox,
        gears: Number(this.vehicle.gears),
        doors: Number(this.vehicle.doors),
        seats: Number(this.vehicle.seats),
        serviceHistory: this.vehicle.serviceHistory,
        stockCode: this.vehicle.stockCode,
        notes: this.vehicle.notes,
        category: this.vehicle.category,
        priority: Number(this.vehicle.priority),
      });
      if (newVehicle) {
        this.$router.replace(`/vehicle/${newVehicle.id}`);
      } else {
        console.error('error adding vehicle');
        this.showErrorModal('Error adding vehicle. Please try again.');
      }
    },
    showErrorModal(message: string) {
      this.isErrorModalVisible = true;
      this.errorMessage = message;
    },
    closeErrorModal() {
      this.isErrorModalVisible = false;
      this.errorMessage = "";
    },
    async handleFileUpload() {
      const inputElement = this.$refs.fileInput as HTMLInputElement;
      const files = inputElement.files;
      // Store selected files in a data property or component state
      if (files) {
        // Filter selected files to allow only JPG and PNG
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const filteredFiles = Array.from(files).filter((file) => {
          const extension = file.name.split(".").pop()?.toLowerCase();
          return extension && allowedExtensions.includes(extension);
        });
        // Explicitly cast 'files' to an array using Array.from
        this.selectedImages = filteredFiles as File[];

        for (const file of filteredFiles) {

          const blobName = uuidv4();
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);

          await blockBlobClient.uploadBrowserData(file);
          this.vehicle.images.push(blockBlobClient.url);
        }
      }
    },
    // Method to show the remove confirmation dialog
    showRemoveConfirmation(index: number) {
      this.imageIndexToRemove = index;
      this.isRemoveConfirmationVisible = true;
    },

    // Method to close the remove confirmation modal
    closeRemoveConfirmationModal() {
      this.isRemoveConfirmationVisible = false;
    },

    // Method to remove the image when "Remove" is clicked in the dialog
    async removeImageConfirmed() {
      if (this.imageIndexToRemove === null) {
        this.showErrorModal('Error removing image. Please try again.');
        return;
      }
      const blobName = extractUUIDFromBlobUrl(this.vehicle.images[this.imageIndexToRemove!]);
      if (blobName) {
        const blobClient = containerClient.getBlockBlobClient(blobName);

        try {
          await blobClient.delete();
          this.vehicle.images.splice(this.imageIndexToRemove, 1);
          this.imageIndex = 0;
          this.isRemoveConfirmationVisible = false;
          this.imageIndexToRemove = null;
          // console.log(`Image "${blobName}" deleted successfully.`);
        } catch (error) {
          console.error(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
          this.showErrorModal(`Error deleting blob "${blobName}": ${(error as { message: string }).message}`);
        }
      } else {
        this.showErrorModal('Error, Blob is null. Please try again.');
      }
    },
    async saveFileLocally(file: File) {
      const reader = new FileReader();

      reader.onload = (event) => {
        // The file content is available here as event.target.result
        const fileContent = event.target?.result;

        if (typeof fileContent === "string") {
          // Create a blob with the file content
          const blob = new Blob([fileContent], { type: file.type });

          // Create a URL for the blob data
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = file.name;

          // Trigger a click event to download the file
          a.click();

          // Clean up the URL object
          window.URL.revokeObjectURL(url);
        }
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    },
  },
});
</script>

<style scoped lang="scss">
.container {
  margin-top: 50px;
  padding-top: 40px;
  color: #2e323c;
  background: #ffffff;
  position: relative;
  height: 100%;
  justify-content: center;
  width: 100% !important;
  min-height: 100vh;
}

.modal-overlay {
  position: fixed;
  /* Position the modal overlay relative to the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the modal appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-title {
  text-align: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
}

.heading-info {
  color: #2b2b2b !important;
  font-size: 1.5em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.vButton {
  width: 25ex !important;
  margin: 10px;
}

.form-control {
  border: 1px solid #cfd1d8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 2px;
  border-radius: 5px;
  font-size: 0.825rem;
  background: #ffffff;
  color: #2e323c;
  width: 70%;
  text-align: center;
}

.form-group {
  display: flex;
  margin-top: 5px;
}

.card {
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  margin-bottom: 1rem;
}

.input-wrapper {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.form-label {
  width: 25ex;
  text-align: left;
}

/*.small-image-container {
  min-width: 10em;
  max-width: 57em;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: 1px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
}*/

.image-scroll {
  display: flex;
}

/*.image-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: 1px !important;
}*/

.image-container {
  position: relative;
  display: inline-block;
  margin: 0.5em;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
  min-width: fit-content;
  height: 7em !important;
  text-align: right;
  /* Align the "X" to the right */
  overflow: hidden;
  /* Hide the overflow of the "X" icon */
  position: relative;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(255, 10, 10);
  color: white;
  border-radius: 0 10px 0 0;
  /* Rounded corner for the "X" icon */
  padding: 0.2em 0.4em;
  cursor: pointer;
}

.small-image {
  min-width: 7em !important;
  height: 7em !important;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}

.small-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
}

.plus-icon {
  font-size: 1.5em;
  transition: 0.2s ease;
}

.plus-icon:hover {
  font-size: 1.7em;
  color: blue;
}

::-webkit-scrollbar {
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.vCloss {
  background-color: #fff;
  border-radius: 10px;
}

.note-form-control {
  border: 1px solid #cfd1d8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 2px;
  border-radius: 5px;
  font-size: .825rem;
  background: #ffffff;
  color: #2e323c;
  width: 100% !important;
  text-align: left !important;
  min-height: 5em !important;
}

.input-multi {
  width: 100%
}

.required {
  color: red;
  margin-left: 4px;
}

.modal-title-error{
  color: red;
  margin-top: 5px;
}

.error-message{
  color: #1a1a1a;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
</style>