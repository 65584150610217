import axios from "axios";
import { API_URL } from "./common";
// import https from 'https';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
// const agent = new https.Agent({
//   rejectUnauthorized: false
// });

export type Vehicle = {
  id?: string;
  title: string;
  images: string[];
  mileage: number;
  price: number;
  color?: string;
  dateOfLicensing?: string;
  serviceHistory?: string;
  stockCode?: string;
  make?: string;
  model?: string;
  bodyType?: string;
  gearbox?: string;
  gears?: number;
  fuelType?: string;
  fuelTankCapacity?: number;
  doors?: number;
  seats?: number;
  notes?: string;
  priority?: number;
  category?: string;
}

const vehiclesQuery = `query Vehicles ($id: String, $title: String) {
    vehicles (id: $id, title: $title) {
      id
      title
      mileage
      images
      price
      priority
      category
      dateOfLicensing
    }
  }`;

const singleVehicleQuery = `query Vehicles ($id: String, $title: String) {
    vehicles (id: $id, title: $title) {
      id
      createdAt
      title
      images
      mileage
      price
      color
      dateOfLicensing
      serviceHistory
      stockCode
      make
      model
      bodyType
      gearbox
      gears
      fuelType
      fuelTankCapacity
      doors
      seats
      notes
      category
      priority
    }
  }`;

const createVehicleMutation = `mutation CreateVehicle(
    $title: String
    $images: [String]
    $mileage: Int
    $price: Int
    $color: String
    $dateOfLicensing: String
    $serviceHistory: String
    $stockCode: String
    $make: String
    $model: String
    $bodyType: String
    $gearbox: String
    $gears: Int
    $fuelType: String
    $fuelTankCapacity: Int
    $doors: Int
    $seats: Int
    $notes: String
    $category: String
    $priority: Int
  ) {
    createVehicle(
      title: $title
      images: $images
      mileage: $mileage
      price: $price
      color: $color
      dateOfLicensing: $dateOfLicensing
      serviceHistory: $serviceHistory
      stockCode: $stockCode
      make: $make
      model: $model
      bodyType: $bodyType
      gearbox: $gearbox
      gears: $gears
      fuelType: $fuelType
      fuelTankCapacity: $fuelTankCapacity
      doors: $doors
      seats: $seats
      notes: $notes
      category: $category
      priority: $priority
    ) {
      id
      createdAt
      title
      images
      mileage
      price
      color
      dateOfLicensing
      serviceHistory
      stockCode
      make
      model
      bodyType
      gearbox
      gears
      fuelType
      fuelTankCapacity
      doors
      seats
      notes
      category
      priority
    }
  }`;

const updateVehicleMutation = `mutation UpdateVehicle(
    $id: String
    $title: String
    $images: [String]
    $mileage: Int
    $price: Int
    $color: String
    $dateOfLicensing: String
    $serviceHistory: String
    $stockCode: String
    $make: String
    $model: String
    $bodyType: String
    $gearbox: String
    $gears: Int
    $fuelType: String
    $fuelTankCapacity: Int
    $doors: Int
    $seats: Int
    $notes: String
    $category: String
    $priority: Int
  ) {
    updateVehicle(
      id: $id
      title: $title
      images: $images
      mileage: $mileage
      price: $price
      color: $color
      dateOfLicensing: $dateOfLicensing
      serviceHistory: $serviceHistory
      stockCode: $stockCode
      make: $make
      model: $model
      bodyType: $bodyType
      gearbox: $gearbox
      gears: $gears
      fuelType: $fuelType
      fuelTankCapacity: $fuelTankCapacity
      doors: $doors
      seats: $seats
      notes: $notes
      category: $category
      priority: $priority
    ) {
      id
      createdAt
      title
      images
      mileage
      price
      color
      dateOfLicensing
      serviceHistory
      stockCode
      make
      model
      bodyType
      gearbox
      gears
      fuelType
      fuelTankCapacity
      doors
      seats
      notes
      category
      priority
    }
  }`;

const deleteVehicleMutation = `mutation DeleteVehicle($id: String) {
    deleteVehicle(id: $id) {
      id
      createdAt
      title
      images
      mileage
      price
      color
      dateOfLicensing
      serviceHistory
      stockCode
      make
      model
      bodyType
      gearbox
      gears
      fuelType
      fuelTankCapacity
      doors
      seats
      notes
      category
      priority
    }
  }
  `;

/**
 * Function to fetch vehicles
 * @param args {Record} - Arguments to pass to the query
 * @param args.id {string} - ID of the vehicle to fetch
 * @param args.title {string} - Title of the vehicle to fetch
 */
export const fetchVehicles = async (args: { id?: string, title?: string }) => {
  const response = await axios.post(API_URL, {
    query: vehiclesQuery,
    variables: { id: args.id, title: args.title },
    // httpsAgent: agent
  });

  if (response.data.errors) {
    return undefined;
  }

  const res = response.data.data;
  if (res) {
    return res.vehicles as Vehicle[];
  }
  return undefined;
};

export const fetchSingleVehicle = async (args: { id: string }) => {
  const response = await axios.post(API_URL, {
    query: singleVehicleQuery,
    variables: { id: args.id },
    // httpsAgent: agent
  });

  if (response.data.errors) {
    return undefined;
  }

  const res = response.data.data;
  if (res) {
    return res.vehicles[0] as Vehicle;
  }
  return undefined;
};

export const createVehicle = async (args: {
  title: string,
  images: string[],
  mileage: number,
  price: number,
  color?: string,
  dateOfLicensing?: string,
  serviceHistory?: string,
  stockCode?: string,
  make?: string,
  model?: string,
  bodyType?: string,
  gearbox?: string,
  gears?: number,
  fuelType?: string,
  fuelTankCapacity?: number,
  doors?: number,
  seats?: number,
  notes?: string,
  category?: string,
  priority?: number,
}) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return undefined;
  }

  const response = await axios.post(API_URL, {
    query: createVehicleMutation,
    variables: args,
    // httpsAgent: agent
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  if (response.data.errors) {
    return undefined;
  }

  const res = response.data.data;
  if (res) {
    return res.createVehicle as Vehicle;
  }
  return undefined;
};

export const updateVehicle = async (args: {
  id: string,
  title?: string,
  images?: string[],
  mileage?: number,
  price?: number,
  color?: string,
  dateOfLicensing?: string,
  serviceHistory?: string,
  stockCode?: string,
  make?: string,
  model?: string,
  bodyType?: string,
  gearbox?: string,
  gears?: number,
  fuelType?: string,
  fuelTankCapacity?: number,
  doors?: number,
  seats?: number,
  notes?: string,
  category?: string,
  priority?: number,
}) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return undefined;
  }

  const response = await axios.post(API_URL, {
    query: updateVehicleMutation,
    variables: args,
    // httpsAgent: agent
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  if (response.data.errors) {
    return undefined;
  }

  const res = response.data.data;
  if (res) {
    return res.updateVehicle as Vehicle;
  }
  return undefined;
}

export const deleteVehicle = async (args: { id: string }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return undefined;
  }

  const response = await axios.post(API_URL, {
    query: deleteVehicleMutation,
    variables: args,
    // httpsAgent: agent
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  if (response.data.errors) {
    return undefined;
  }

  const res = response.data.data;
  if (res) {
    return res.deleteVehicle as Vehicle;
  }
  return undefined;
}