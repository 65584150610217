<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-blue mb-4" id="navbar">
        <div class="container-fluid">
            <router-link to="/" class="fj-motors-logo">
                <img src="../assets/fjmotors_logo.jpg" alt="FJ MOTORS Logo">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/" :class="(activePage === 'home') ? 'my-nav-item nav-link active' : 'my-nav-item nav-link'">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link v-if="isAdmin" to="/addVehicle" :class="(activePage === 'addvehicle') ? 'my-nav-item nav-link active' : 'my-nav-item nav-link'">Add Vehicle</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact" :class="(activePage === 'contact') ? 'my-nav-item nav-link active' : 'my-nav-item nav-link'">Contact</router-link>
                    </li>
                </ul>
                <div class="nav-item dropdown my-dropdown user-logo">
                    <a class="nav-link  text-white" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-user fa-lg"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                        <router-link v-if="isAdmin" to="/profile" class="dropdown-item">Profile</router-link>
                        <li v-if="isAdmin"><a class="dropdown-item" href="#">Settings</a></li>
                        <li v-if="isAdmin">
                            <hr class="dropdown-divider">
                        </li>
                        <router-link v-if="!isAdmin" to="/login" class="dropdown-item">Login</router-link>
                        <li v-else><a class="dropdown-item" @click="logout">Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { getUserFromClaims } from '@/api/user';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavBar',
    data() {
        return {
            isAdmin: false,
            activePage: '',
        }
    },
    async beforeMount() {
        if (this.$route.path === '/') {
            this.activePage = 'home';
        } else if (this.$route.path.includes('contact')) {
            this.activePage = 'contact';
        }else if (this.$route.path.includes('addVehicle')) {
            this.activePage = 'addvehicle';
        }

        const user = this.$store.getters['user/getCurrentUser'];
        if (user) {
            this.isAdmin = true;
        } else {
            const userFromClaims = await getUserFromClaims();
            if (userFromClaims) {
                this.$store.dispatch('user/setCurrentUser', userFromClaims);
                this.isAdmin = true;
            } else {
                localStorage.removeItem('token');
                if (this.$route.path.includes('addVehicle') ||
                    this.$route.path.includes('editVehicle') ||
                    this.$route.path.includes('profile')) {
                    this.$router.replace('/login');
                }
            }

        }

    },
    beforeDestroy() {
        this.isAdmin = false;
    },
    methods: {
        logout() {
            this.$store.dispatch('user/setCurrentUser', null);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.reload();
        }
    }
})
</script>

<style scoped lang="scss">
.fj-motors-logo {
    img {
        width: 13em;
        height: auto;
        display: block;
        margin-left: 10px;
        margin-right: 20px;
    }
}

.navbar-title {
    font-size: 1.5em;
    font-weight: bolder;
}

.navbar {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Adjust the values as needed */
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
}

.bg-blue {
    background-color: #063E88;
    transition: background-color 0.3s ease;
}

.fj-motors-logo-font {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: xx-large;
    text-decoration: none;
    color: rgb(212, 0, 0);
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    letter-spacing: 0.03em;
    padding-right: 1ex;
    padding-left: 0.5ex;
    transition: font-size 0.2s ease, transform 0.2s ease;
}

.fj-motors-logo-font:hover {
    font-size: 33px;
    transform: translateY(-2px);
}

/*.nav-link {
  transition: font-size 0.2s ease, transform 0.2s ease;
}

.nav-link:hover {
  font-size: large;
  transform: translateY(-5px);
}*/

.my-nav-item {
    color: #dddddd !important;
    font-weight: 500 !important;
    transition: font-size 0.2s ease, transform 0.2s ease;
}

.my-nav-item:hover {
    color: #fff !important;
    //font-size: large;
    transform: translateY(-3px);
}

.active {
    color: #ffffff !important;
}

.my-dropdown {
    padding: 5px !important;
    z-index: 10 !important;
}

.user-logo {
    transition: font-size 0.3s ease, transform 0.2s ease;
}

.user-logo:hover {
    //font-size: 2.2ex;
    //transform: translateY(-3px);
}


.dropdown-menu {
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    max-height: 50em;
    max-width: 20em;
    width: 15em;
}


.dropdown-item {
    transition: font-size 0.1s ease, transform 0.1s ease;
    color: #626262 !important;
}

.dropdown-item:hover {
    background-color: #ceeaff;
    transform: translateY(-2px);
    color: #000 !important;
    //font-weight: 600;
}
</style>
