<template>
  <div class="page-container">
    <NavBar />

    <main class="content">
      <!-- search bar -->
      <div class="row my-row" v-if="!isMobile()">
        <div class="category-button-container">
          <div class="category-button-scroll">
            <button class="category-button" @click="setCategory('sedan')" :class="{ active: categoryValue === 'sedan' }">
              Sedan
              <img class="car-image" src="../assets/sedan.png">
            </button>
            <button class="category-button" @click="setCategory('hatchback')"
              :class="{ active: categoryValue === 'hatchback' }">
              Hatchback
              <img class="car-image" src="../assets/hatchback.png">
            </button>
            <button class="category-button" @click="setCategory('suv')" :class="{ active: categoryValue === 'suv' }">
              SUV
              <img class="car-image" src="../assets/suv.png">
            </button>
            <button class="category-button" @click="setCategory('singleCab')"
              :class="{ active: categoryValue === 'singleCab' }">
              Single Cab
              <img class="car-image" src="../assets/singlecab.png">
            </button>
            <button class="category-button" @click="setCategory('doubleCab')"
              :class="{ active: categoryValue === 'doubleCab' }">
              Double Cab
              <img class="car-image" src="../assets/doublecab.png">
            </button>
            <button class="category-button" @click="setCategory('sevenSeater')"
              :class="{ active: categoryValue === 'sevenSeater' }">
              7-Seater
              <img class="car-image" src="../assets/sevenseater.png">
            </button>
            <button class="category-button" @click="setCategory('bus')" :class="{ active: categoryValue === 'bus' }">
              Bus
              <img class="car-image-bus" src="../assets/bus.png">
            </button>
          </div>
        </div>


        <div class="d-flex justify-content-center search-div"> <!-- Center align the content -->
          <form class="form-inline">
            <div class="input-group"> <!-- Group the input and button together -->
              <input v-model="searchValue" class="form-control searchbar" type="search" placeholder="Search"
                aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-outline-success search-button" type="submit" v-if="!isMobile()">
                  Search <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-outline-success search-button" type="submit" v-if="isMobile()">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
          <!-- filters -->
          <FilterSearch class="filter-component" />

          <!-- sort -->
          <SortSearch class="sort-component" />

          <button class="btn" @click="clear" v-if="isClearVisible"><b>clear</b></button>
        </div>
      </div>

      <!-- search bar -->
      <div class="row my-row" v-if="isMobile()">
        <div class="category-button-container">
          <div class="category-button-scroll">
            <button class="category-button-mobile" @click="setCategory('sedan')"
              :class="{ active: categoryValue === 'sedan' }">
              <img class="car-image-mobile" src="../assets/sedan.png">
              <p>Sedan</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('hatchback')"
              :class="{ active: categoryValue === 'hatchback' }">
              <img class="car-image-mobile" src="../assets/hatchback.png">
              <p>Hatchback</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('suv')"
              :class="{ active: categoryValue === 'suv' }">
              <img class="car-image-mobile" src="../assets/suv.png">
              <p>SUV</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('singleCab')"
              :class="{ active: categoryValue === 'singleCab' }">
              <img class="car-image-mobile" src="../assets/singlecab.png">
              <p>Single Cab</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('doubleCab')"
              :class="{ active: categoryValue === 'doubleCab' }">
              <img class="car-image-mobile" src="../assets/doublecab.png">
              <p>Double Cab</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('sevenSeater')"
              :class="{ active: categoryValue === 'sevenSeater' }">
              <img class="car-image-mobile" src="../assets/sevenseater.png">
              <p>7-Seater</p>
            </button>
            <button class="category-button-mobile" @click="setCategory('bus')"
              :class="{ active: categoryValue === 'bus' }">
              <img class="car-image-bus-mobile" src="../assets/bus.png">
              <p>Bus</p>
            </button>
          </div>
        </div>


        <div class="d-flex justify-content-center search-div">
          <form class="form-inline">
            <div class="input-group">
              <input v-model="searchValue" class="form-control searchbar" type="search" placeholder="Search"
                aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-outline-success search-button" type="submit" v-if="!isMobile()">
                  Search <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-outline-success search-button" type="submit" v-if="isMobile()">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
          <!-- filters -->
          <FilterSearch class="filter-component" />

          <!-- sort -->
          <SortSearch class="sort-component" />

          <button class="btn clear-mobile" @click="clear" v-if="isClearVisible"><b>clear</b></button>
        </div>
      </div>
      <!-- vehicles -->
      <Vehicles :search-value="searchValue" :category-value="categoryValue" />
    </main>

    <!-- footer -->
    <FooterComponent />

  </div>
</template>

<script lang="ts">
import NavBar from '@/components/Navbar.vue';
import FilterSearch from '@/components/FilterSearch.vue';
import Vehicles from '@/components/Vehicles.vue';
import Footer from '@/components/Footer.vue';
import { defineComponent } from 'vue';
import SortSearch from '@/components/sortSearch.vue';
import { isMobileDevice } from '@/common/commonFunctions';


export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
    FilterSearch,
    Vehicles,
    FooterComponent: Footer,
    SortSearch
  },
  data() {
    return {
      searchValue: '',
      categoryValue: '',
      isClearVisible: false
    };
  },
  computed: {
    isFilterOn(): boolean | number {
      return this.$store.getters['vehicle/getFilterOn'];
    },
    isSortOn(): boolean | number {
      return this.$store.getters['vehicle/getSortOn'];
    }
  },
  watch: {
    isFilterOn(newValue, oldValue) {
      if (newValue === true || typeof newValue === 'number') {
        this.isClearVisible = true;
      }
    },
    isSortOn(newValue, oldValue) {
      if (newValue === true || typeof newValue === 'number') {
        this.isClearVisible = true;
      }
    }
  },

  async mounted() {
    const id = this.$route.query.id;
    if (id) {
      this.$router.push({ path: `/vehicle/${id}` });
    }

    this.clear();
  },
  methods: {
    isMobile() {
      return isMobileDevice();
    },
    setCategory(value: string) {
      this.categoryValue = value;
      this.isClearVisible = true;
      this.searchValue = '';
    },
    clear() {
      this.categoryValue = '';
      this.searchValue = '';
      this.$store.dispatch('vehicle/clearFilters');
      this.$store.dispatch('vehicle/clearSort');
      this.isClearVisible = false;
    }
  }
});
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the container takes up at least the viewport height */
}

.content {
  flex: 1;
  /* Allow the content to grow and take available space */
  margin-top: 70px;
}

.search-bar {
  width: 100%;
}

.filter-component {
  margin-left: 0.5em;
}

.sort-component {
  margin-left: 0.5em;
  margin-right: 0.5em;
}


.searchbar {
  margin-left: 0.2em;
  border-right: 1px solid #757575;
  //transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

/*.searchbar:hover,
.searchbar:focus{
  width: 100%;
}*/

.search-button {
  background-color: transparent;
  border: 1px solid rgb(0, 49, 128);
  border-left: 0px;
  color: rgb(0, 49, 128);
  padding: 6px 12px;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
}

.search-button:hover,
.search-button:focus {
  color: white;
  background-color: rgb(0, 67, 175);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}

.search-div {
  margin-bottom: 10px;
}

.my-row {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f0f0f0 !important;
}


.category-button {
  background-color: transparent;
  border: 1px solid rgb(0, 60, 156);
  color: rgb(0, 67, 175);
  width: 9em;
  height: 2.5em;
  font-size: 1.75ex;
  font-weight: 550;
  //padding: 6px 12px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  transform: translateY(0);
}

.category-button:hover,
.category-button:focus {
  box-shadow: 0px 0px 10px rgba(54, 133, 207, 0.9);
  outline: none;

  .car-image {
    transform: translateX(0.5ex);
  }

  .car-image-bus {
    transform: translateX(0.5ex);
  }

}

.category-button.active {
  box-shadow: 0px 0px 10px rgba(54, 133, 207, 0.9);
  outline: none;
}

.category-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.category-button-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  margin-bottom: 5px;
}

::-webkit-scrollbar {
  height: 2px !important;
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}


.category-button-mobile {
  background-color: transparent;
  border: 1px solid rgb(0, 60, 156);
  color: rgb(0, 67, 175);
  width: 9em;
  height: 3em;
  font-size: 1.75ex;
  font-weight: 550;
  //padding: 6px 12px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  transform: translateY(0);
}

.category-button-mobile:hover,
.category-button-mobile:focus {
  box-shadow: 0px 0px 10px rgba(54, 133, 207, 0.9);
  outline: none;
}

.category-button-mobile.active {
  box-shadow: 0px 0px 10px rgba(54, 133, 207, 0.9);
  outline: none;
}

.car-image {
  height: 4ex;
  width: 4ex;
  margin-left: 3px;
  transition: transform 0.3s;
}

.car-image-bus {
  height: 4ex;
  width: 4ex;
  margin-left: 3px;
  transition: transform 0.3s;
}

.car-image-mobile {
  height: 3ex;
  width: 3ex;
  margin-bottom: -5px;
  transition: transform 0.3s;
}

.car-image-bus-mobile {
  height: 3ex;
  width: 3ex;
  margin-bottom: -5px;
  transition: transform 0.3s;
}

.clear-mobile {
  margin-left: -10px;
  margin-right: -10px;
}</style>
